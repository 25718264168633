<template>
    <div>
        <div>
        <el-row>
            <el-col :span="24">
                <el-row>
                    <el-col :span="6">
                        <el-image
                        style="width: 100%; height: 130px;border-radius:5px;"
                        src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                    </el-col>
                    <el-col :span="6" :offset="1">
                        <div style="height:130px;display: table;">
                            <div style="display: table-cell;vertical-align:middle;text-align:center;">
                                <p style="font-weight:bold">aa</p>
                                <p style="margin-top:20px">bb</p>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        </div>
    </div>
</template>
<script>
export default {
    name:"orderList",
    data(){
        return{
            
        }
    },
    created(){

    },
    components:{
        
    },
    computed: {

    },
    updated() {
        
    },
    methods:{

    }
}
</script>