<template>
  <div class="accountRelevanceBg">
    <div>
      <div class="amendBackground">
        <div @click="changeAmendPassword">
          <img src="images/amendPassword.png" />
          <p>修改密码</p>
        </div>
      </div>
      <div class="amendBackground">
        <div @click="deleteAccount">
          <img src="images/loginout.png" />
          <p>注销账号</p>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="amendPasswordWindow" :show-close="true" title="修改密码">
      <div class="amendPasswordPadding">
        <div>
<!--          <p>原密码:</p>-->
          <el-input show-password v-model="form.oldPassword" placeholder="请输入旧密码"></el-input>
        </div>
        <div>
<!--          <p>新密码:</p>-->
          <el-input show-password placeholder="设置6~16位新密码" v-model="form.newPassword"></el-input>
        </div>
        <div>
<!--          <p>确认密码:</p>-->
          <el-input show-password v-model="form.affrimPassword" placeholder="请确认密码"></el-input>
        </div>
        <div style="display: flex;justify-content: space-between">
          <el-button type="primary" @click="submitAmendPassword">修改密码</el-button>
          <p style="cursor: pointer;color: #409EFF"  @click="goReg">忘记密码?</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="phoneWindow"
        :show-close="true"
        title="绑定手机号">
      <div class="phoneWindowPadding">
        <div>
          <el-input placeholder="请输入手机号" v-model="amendPhoneData"></el-input>
        </div>
        <div class="accountCodeFlex">
          <el-input placeholder="请输入验证码" v-model="amendCodeData"></el-input>
          <el-button v-if="regtimeshow" type="primary" @click="getCode">获取验证码</el-button>
          <el-button v-if="!regtimeshow">{{ count }}s后重新获取</el-button>
        </div>
        <div>
          <el-input placeholder="请输入新密码" show-password v-model="passwordSetData"></el-input>
        </div>
        <div>
          <el-input placeholder="请确认密码" show-password  v-model="affrimPasswordSetData"></el-input>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center">
          <el-button type="primary" @click="submitPhone">保存</el-button>
          <p style="cursor: pointer;color: #409EFF"  @click="goReg">忘记密码?</p>
        </div>
      </div>
    </el-dialog>
<!--    <el-dialog-->
<!--        :visible.sync="setPasswordWindow"-->
<!--        :show-close="true"-->
<!--        title="设置密码">-->
<!--      <div class="phoneWindowPadding">-->
<!--        <div>-->
<!--          <el-input placeholder="请输入手机号" v-model="passwordPhoneData"></el-input>-->
<!--        </div>-->
<!--        <div class="accountCodeFlex">-->
<!--          <el-input placeholder="请输入验证码" v-model="passwordCodeData"></el-input>-->
<!--          <el-button v-if="passwordTimeshow" type="primary" @click="getPasswordCode">获取验证码</el-button>-->
<!--          <el-button v-if="!passwordTimeshow" >{{ passwordCount }}s后重新获取</el-button>-->
<!--        </div>-->
<!--        <div>-->
<!--          <el-input placeholder="请输入密码" show-password v-model="passwordSetData"></el-input>-->
<!--        </div>-->
<!--        <div>-->
<!--          <el-button type="primary" @click="submitPassword">保存</el-button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </el-dialog>-->
  </div>
</template>
<script>
import {
  amendUserPhone,
  logoutAccount, sendPhoneCode, studentPasswordLogin, studentUpPassword,userBoundPhonePsd
} from "@/api";
export default {
  components: {
  },
  data(){
    return{
      amendPasswordWindow:false,
      form:{
        oldPassword:'',
        newPassword:'',
        affrimPassword:''
      },
      phoneWindow:false,
      amendPhoneData:'',
      amendCodeData:'',
      regtimeshow:true,
      count: "",
      passwordPhoneData:'',
      passwordCodeData:'',
      passwordTimeshow:true,
      passwordCount:'',
      passwordSetData:'',
      affrimPasswordSetData:''
    }
  },
  mounted() {
  },
  methods: {
    //去忘记密码
    goReg(){
      this.$router.push({path:'/reg',query:{forgetPassword:1}})
    },
    //修改
    changeAmendPassword(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo&&userInfo.telNumber){
        this.amendPasswordWindow=true
      }else{
        this.phoneWindow=true
      }
    },
    //修改密码
    submitAmendPassword(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (!this.form.oldPassword) {
        this.$message.error("旧密码不可为空");
        return;
      }
      if (!/^[a-zA-Z0-9]{6,16}$/.test(this.form.newPassword)) {
        this.$message.error("新密码格式不对");
        return;
      }
      if(this.form.newPassword!==this.form.affrimPassword){
        this.$message.error("两次输入的密码不一致");
        return;
      }
      let data={
        oldPassword:this.form.oldPassword,
        newPassword:this.form.affrimPassword,
        terminalType:'WEB',
        telNumber:userInfo.telNumber
      }
      studentUpPassword(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.$router.push('/reg')
        }
      })
    },
    //注销
    deleteAccount(){
      this.$confirm('确定要注销您的账号？继续将注销此帐号，注销后此帐号中的所有将无法恢复，确认注销?', '确定要注销您的账号？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        logoutAccount().then(res=>{
          if(res.code===0){
            this.$message({
              type: 'success',
              message: '注销成功!'
            });
          }else{
            this.$message.error(res.msg);
          }
        })

      }).catch(() => {

      });
    },
    //  获取验证码
    getCode(){
      if (!/^[1]([0-9])[0-9]{9}$/.test(this.amendPhoneData)) {
        this.$message.error("手机格式不对");
        return;
      }
      let TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.regtimeshow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.regtimeshow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      };
      let data={
        phone:this.amendPhoneData
      };
      sendPhoneCode(data).then(res=>{
        if(res.code!==0){
          this.$message.error(res.msg)
        }
      })
    },
    //获取设置手机号修改密码
    getPasswordCode(){
      if (!/^[1]([0-9])[0-9]{9}$/.test(this.passwordPhoneData)) {
        this.$message.error("手机格式不对");
        return;
      }
      let TIME_COUNT = 60;
      if (!this.timerP) {
        this.passwordCount = TIME_COUNT;
        this.passwordTimeshow = false;
        this.timerP = setInterval(() => {
          if (this.passwordCount > 0 && this.passwordCount <= TIME_COUNT) {
            this.passwordCount--;
          } else {
            this.passwordTimeshow = true;
            clearInterval(this.timerP);
            this.timerP = null;
          }
        }, 1000);
      };
      let data={
        phone:this.passwordPhoneData
      };
      sendPhoneCode(data).then(res=>{
        if(res.code!==0){
          this.$message.error(res.msg)
        }
      })
    },
    //  保存绑定手机号
    submitPhone(){
      if(!this.passwordSetData){
        this.$message.error("密码不可为空");
        return;
      }
      if(this.passwordSetData!==this.affrimPasswordSetData){
        this.$message.error("两次密码不一致");
        return;
      }
      let data={
        telNumber:this.amendPhoneData,
        verifyCode:this.amendCodeData,
        newPassword:this.affrimPasswordSetData,
        terminalType:'WEB'
      };
      userBoundPhonePsd(data).then(res=>{
        if(res.code==0){
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.phoneWindow=false;
          this.$cookie.set("userToken", res.data.loginToken);
          localStorage.setItem('userInfo',JSON.stringify(res.data));
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>
<style scoped>
.accountRelevanceBg{
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  padding: 48px;
  border-radius: 6px;
  background: white;
}
.accountRelevanceBg>div{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.amendBackground{
  background: #F7F7F7;
  width: 49%;
  height: 420px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.amendBackground>div{
  cursor: pointer;
}
.amendPasswordPadding{
  padding: 0 130px;
}
.amendPasswordPadding>div{
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
/*.amendPasswordPadding>div>p{*/
/*  width: 100px;*/
/*}*/
.amendPasswordPadding>div>button{
  /*width: 100%;*/
  /*font-size: 18px;*/
}
.phoneWindowPadding{
  padding: 12px 160px;
}
.phoneWindowPadding>div{
  margin-bottom: 18px;
}
.accountCodeFlex{
  display: flex;
  align-items: center;
}
.accountCodeFlex>button{
  margin-left: 10px;
}
</style>
