<template>
  <div class="postCourseBg">
    <div style="width: 100%" @click="hideMaskPopup">
      <div class="morePOst" @click="table = true">
        <p>更多职位</p>
      </div>
      <div class="postCourseWd">
        <div class="postCoursePad">
          <div>
            <p>{{courseClassFyDetailObj.name}}</p>
            <p class="learningFlex">
              <span>系统特价: </span>
              <span style="font-size: 28px;color: #FF0000">¥{{postTotalPrice}}</span>
            </p>
            <p class="learningFlex" style="height: 80px">
              <span>学习目的： </span>
              <span v-html="courseClassFyDetailObj.profiles"></span>
            </p>
            <div class="learningFlex" style="margin-top: 20px">
              <span>公司承诺: </span>
              <div class="chengnuo" >
                <p>
                  <img src="images/postIconone.png">
                  <span>师资认证</span>
                </p>
                <p>
                  <img src="images/postIconone2.png">
                  <span>系统实战</span>
                </p>
                <p>
                  <img src="images/postIconone3.png">
                  <span>支付安全</span>
                </p>
              </div>
            </div>
            <div class="learningFlex" style="margin-top: 40px">
              <el-button type="primary" style="width: 280px;" @click="goBuyCourse('type')">立即购买</el-button>
              <img style="cursor: pointer;margin: 0 20px;width: 30px" src="images/postCarShow2.png" v-if="!postCarShow" @click="joinBuyShopping">
<!--              <img style="cursor: pointer;margin: 0 20px;width: 30px" src="images/postCarShow.png" v-if="postCarShow" @click="joinBuyShopping">-->
              <img src="images/share.png" style="width: 19px;cursor: pointer;margin-left: 20px;" @click="share">
<!--              <i class="el-icon-share" style="font-size: 28px;cursor: pointer" @click="share"></i>-->
              <p class="allTotal" @click="goBuyCourse('1')">购买全系礼包，全部课程随意看</p>
            </div>

          </div>
          <div class="postCoursePadth">
            <img :src="courseClassFyDetailObj.detailsIconUrl?courseClassFyDetailObj.detailsIconUrl:'images/zanwuImg2.png'">
            <div style="display: flex;margin-top: 20px">
              <p class="postCourseNumber">
                <span>课程数量：</span>
                <span>{{courseTotal}}门</span>
              </p>
              <p class="postCourseNumber">
                <span>学习人次：</span>
                <span>{{courseClassFyDetailObj.studyNumber}}</span>
              </p>
              <p class="postCourseNumber">
                <span>课程时长：</span>
                <span>{{ parseInt(courseClassFyDetailObj.courseDuration/60)}}分钟</span>
              </p>
            </div>
          </div>

        </div>
    <!--    -->
        <div class="postCourserDetailContent">
          <div style="width: 884px;background: white">
            <div class="postCourseTab">
              <div style="display: flex;margin-left: 24px">
                <p class="postCourseTabp" @click="changePostCourseTab(0)" >
                  <img v-show="postCourseShows==0" src="images/workplaceIcon.png" style="margin-right: 3px">
                  <img v-show="postCourseShows!==0" src="images/workplaceIcon2.png" style="margin-right: 3px">
                  <span>课程概览</span>
                </p>
                <p class="postCourseTabp1" @click="changePostCourseTab(1)" >
                  <img v-show="postCourseShows==1" src="images/workplaceIcon3.png" style="margin-right: 3px">
                  <img v-show="postCourseShows!==1" src="images/workplaceIcon4.png" style="margin-right: 3px">
                  <span >课程列表</span>
                </p>
                <p class="postCourseTabp2"  @click="changePostCourseTab(2)" >
                  <img v-show="postCourseShows==2" src="images/workplaceIcon5.png" style="margin-right: 3px">
                  <img v-show="postCourseShows!==2" src="images/workplaceIcon6.png" style="margin-right: 3px">
                  <span >评论交流</span>
                </p>
              </div>
            </div>
            <div >
              <div class="postCourseClass" v-if="postCourseShows==1">
                <div style="font-size: 20px;font-weight: bold">{{courseClassFyDetailObj.name}}</div>
                <div style="display: flex">
                  <p class="sortClass" :class="sortShow==index?'sortActive':''" v-for="(item,index) in sortArr" :key="index" @click="changeSort(index)">{{item.name}}</p>
                </div>
              </div>
              <div>
                <div class="postCourserDetailContentBg" >
                  <div class="postCoursePadding"  v-if="postCourseShows==1" v-for="(item,index) in courseLIstArr" :key="index">
                    <div class="postCourseTitleFlex">
                      <img :src="item.logo">
                      <div>
                        <p style="display: flex;align-items: center">
                          <span>{{item.name}}</span>
                          <span style="font-size: 14px;color: #666;margin-left: 6px"> ({{item.sectionNumber}}节)</span>
                        </p>
                        <p style="font-size: 12px;color: #666666">{{item.profiles}}</p>
                      </div>
                    </div>
                    <div>
                      <p v-if="item.resourceDuration">时长：{{parseInt(item.resourceDuration/60)}}分钟</p>
                      <p>{{item.studyPeopleNumber}}人学习</p>
                      <p v-if="item.postPrice" style="color: red">本课程价值{{item.postPrice}}元</p>
                      <el-button size="small" type="primary" plain @click="goCourseDetail(item)" v-if="item.whetherPay==false">免费试看</el-button>
                      <el-button size="small" type="primary" plain @click="goCourseDetail(item)" v-if="item.whetherPay==true">继续学习</el-button>
                    </div>
                  </div>
                  <div class="postCourseIntroducePadding" v-if="postCourseShows==0"  >
                    <div v-html="courseClassFyDetailObj.overview"></div>
                  </div>
                  <div v-if="postCourseShows==2" class="postExchange">
                    <div>
                      <el-input
                          type="textarea"
                          :autosize="{ minRows: 4, maxRows: 6}"
                          placeholder="请输入内容"
                          v-model="textarea2">
                      </el-input>
                      <div class="flex commentMartop">
  <!--                      <p class="flex">-->
  <!--                        <span class="starColor">星级评价：</span>-->
  <!--                        <el-rate v-model="starRvalue"></el-rate>-->
  <!--                      </p>-->
                        <el-button type="primary" @click="submitComment">发布</el-button>
                      </div>
                    </div>
  <!--                  评论区-->
                    <div>
                      <div class="commentContentFlex" v-for="(item,index) in commentList" :key="index">
                        <img :src="item.userHeadPortraitUrl?item.userHeadPortraitUrl:'images/tacitlyAvatar.png'">
                        <div>
                          <p class="commentNameflex">
                            <span>{{item.userNickName?item.userNickName:item.userTelNumber}}</span>
                          </p>
                          <p>{{item.commentContent}}</p>
                          <div v-if="item.revertShow" class="revertInput">
                            <el-input v-model="item.inputContent"></el-input>
                            <el-button type="primary" @click="goRevert(item)" plain>评论</el-button>
                          </div>
                          <div >
  <!--                          <span>2021-06-10  10：55：44</span>-->
                            <div class="commentTimeFlex">
                              <span @click="changeRevert(item.id)" v-if="!item.revertShow" type="primary">回复</span>
                              <span @click="cancleRevert(item.id)" v-if="item.revertShow" type="primary">取消回复</span>
                              <span class="iconHx"></span>
                              <span v-if="!item.commentReplayList" @click="changeCreateCommentReply(item.id)">展开</span>
                              <span v-if="item.commentReplayList"  @click="packUpCommentReply(item.id)">收起</span>
  <!--                            <img src="images/zan.png">-->
  <!--                            <span>2</span>-->
                            </div>
                          </div>
                          <!--二级评论区-->
                          <div class="commentAplayClass" v-if="commentApllayShow">
                            <div class="secondCommentFlex"  v-for="(item1,index1) in item.commentReplayList" :key="index1">
                              <img :src="item1.userHeadPortraitUrl?item1.userHeadPortraitUrl:'images/tacitlyAvatar.png'">
                              <div>
                                <p class="secondCommentColor">
                                  <span>{{item1.userNickName?item1.userNickName:item1.userTelNumber}}</span>
                                  <span class="replyClass">回复</span>
                                  <span>{{item.userNickName?item.userNickName:item.userTelNumber}} : </span>
                                  <span>{{item1.replyContent}}</span>
                                </p>
                                <!--                            <p class="secondCommentTimeFlex">-->
                                <!--                              <span>2010-06-16</span>-->
                                <!--                              <span></span>-->
                                <!--                              <img src="images/zan.png">-->
                                <!--                              <span>6</span>-->
                                <!--                            </p>-->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="margin: 30px 0">
                        <el-pagination
                            class="text_center"
                            background
                            @current-change="handleCommentChange"
                            :current-page.sync="commentPage"
                            :page-size="commentSize"
                            layout="total,  prev, pager, next, jumper"
                            :total="commentTotal"
                        >
                        </el-pagination>
                      </div>
                    </div>
                  </div>
                </div>
  <!--              <div style="margin: 30px 0" v-if="postCourseShow==0">-->
  <!--                <el-pagination-->
  <!--                    class="text_center"-->
  <!--                    background-->
  <!--                    @current-change="handleCourseChange"-->
  <!--                    :current-page.sync="coursePage"-->
  <!--                    :page-size="courseSize"-->
  <!--                    layout="total,  prev, pager, next, jumper"-->
  <!--                    :total="courseTotal"-->
  <!--                >-->
  <!--                </el-pagination>-->
  <!--              </div>-->
              </div>
            </div>
          </div>
          <div>
            <div class="postCourseTeacherList">
              <div>
                <p>授课教师</p>
                <div class="postTeacherList" v-for="(item,index) in courseClassFyDetailObj.teacherInfos" :key="index">
                  <img :src="item.logo?item.logo:'images/zanwuAvatar.png'">
                  <div>
                    <p>{{item.name}}</p>
                    <p class="teacherPOsition">{{item.position}}</p>
                  </div>
                </div>
              </div>
            </div>
  <!--          <div class="postCourseTeacherList">-->
  <!--            <div>-->
  <!--              <p>相关职位</p>-->
  <!--              <div  class="postTeacherListposition" v-for="(item,index) in courseClassifyVos" :key="index">-->
  <!--                <img :src="item.detailsIconUrl?item.detailsIconUrl:'images/zanwuAvatar.png'">-->
  <!--                <div>-->
  <!--                  <p>{{item.name}}</p>-->
  <!--                  <p class="teacherPOsition">{{item.position}}</p>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
          </div>
        </div>
      </div>
    </div>
    <el-drawer
        :visible.sync="table"
        direction="ltr"
        size="280px">
      <div>
        <el-menu
            default-active="0"
            class="el-menu-vertical-demo"
            :default-openeds="openeds">
          <el-submenu v-for="(item, index) in courseDetailArr"
                      :key="index"
                      :index="index+''">
            <template slot="title">
              <div style="display: flex;align-items: center">
                <img :src="item.iconUrl" style="width: 14px;margin-right: 6px">
                <span >{{ item.name }}</span>
              </div>
            </template>
            <el-menu-item-group v-for="(itemChildren, indexChildren) in item.children"
                                :key="indexChildren" >
              <el-menu-item :index="itemChildren.id+''"  @click="changeClassfiy(itemChildren.id,item)">
                <span >{{ itemChildren.name }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
    </el-drawer>
    <div class="postCourseIocn">
      <div @click="buyCourseVip" style="cursor: pointer">
        <img src="images/marketIcon.png">
        <p style="color: #FFB512">VIP</p>
      </div>
      <div style="cursor: pointer" v-for="(item,index) in courseIconArr" :key="index" @click="changeCourseIcon(index)">
        <img :src="item.img">
        <p style="font-size: 12px">{{item.name}}</p>
      </div>
    </div>
    <div class="postPOsition">
      <div class="postCourseAbsolue" v-if="postCourseDetailShow">
        <div>
          <span>合作交流</span>
          <img src="images/deletePOst.png" style="cursor: pointer;width: 16px" @click="deletePostWindow()">
        </div>
        <div class="postForm">
          <el-form ref="marketForm" :rules="rules" :model="marketForm"  label-width="80px">
            <template>
              <p style="font-size: 14px">山东智捷电子商务有限公司</p>
            </template>
            <template>
              <p style="font-size: 10px;color: #97989A;margin: 6px 0 12px 0">山东省济南市历城区荣盛时代国际1-725</p>
            </template>
            <el-form-item label="" prop="name" >
              <el-input v-model="marketForm.name" placeholder="请填写姓名"></el-input>
            </el-form-item>
            <el-form-item label="" prop="phone">
              <el-input v-model="marketForm.phone" placeholder="请填写手机号"></el-input>
            </el-form-item>
            <el-form-item label="" prop="copy">
              <el-input v-model="marketForm.copy" placeholder="请填写公司名称"></el-input>
            </el-form-item>
            <el-form-item label="" prop="remark">
              <el-input v-model="marketForm.remark" type="textarea"
                        :rows="2" placeholder="请填写备注"></el-input>
            </el-form-item>
          </el-form>
          <div style="display: flex;justify-content: flex-end">
            <!--            <el-button type="primary" plain size="small" @click="deletePostWindow">取消</el-button>-->
            <el-button type="primary" size="small" @click="submitCourse('marketForm')" >提交</el-button>
          </div>
        </div>
      </div>
      <div class="postCourseAbsolueimg" v-if="copyAccounts">
        <img src="images/exiaozhiCode.png" >
      </div>
      <div class="postCoursestudyP" v-if="studyShow" >
        <div class="postStudy">
          悄悄学习  惊艳众人
        </div>
        <div class="postStudyBg">
          <div style="display: flex;align-items: center">
            <span>分类VIP</span>
            <img src="images/VIP.png">
          </div>
          <div class="studyBorder">
            <p v-for="(item,index) in courseDetailArr" :key="index" @click="goOrder(item,index)">
              <img :src="item.iconUrl">
              <span>{{item.name}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  courseClassFyDetail,
  courseList,
  commentList,
  createComment,
  commentReplyList,
  createCommentReply,
  findOrderTotal,
  courseClassify, addLeaveWords, addTrolley, checkUserAddTrolley
} from "@/api";
  export default {
    data(){
      return{
        sortArr:[
          {id:1,name:'默认排序'},
          {id:2,name:'热度排序'},
          {id:3,name:'时间排序'},
        ],
        postCourseTabArr:[
          {id:1,name:"课程介绍"},
          {id:2,name:"课程列表"},
          {id:3,name:"评论交流"},
        ],
        postCourseShows:0,
        textarea2:"",
        starRvalue:null,
        revertShow:false,
        claddFyId:"",
        courseClassFyDetailObj:{},
        courseLIstArr:[],
        coursePage:1,
        courseSize:10,
        courseTotal:0,
        userInfo:{},
        commentApllay:'',
        commentList:[],
        commentReplayList:[],
        commentApllayShow:false,
        commentPage:1,
        commentSize:10,
        commentTotal:0,
        courseClassifyVos:[],
        classfiyArr:[],
        table:false,
        classfiyId:'',
        courserAllTotal:'',
        openeds: ['0'],
        courseDetailArr:[],
        classPid:'',
        classProfiles:'',
        classPName:'',
        copyAccounts:false,
        courseIconArr:[
          {id:1,img:'images/marketIcon1.png',name:'微信'},
          {id:2,img:'images/marketIcon2.png',name:'客服'},
          {id:3,img:'images/marketIcon3.png'},
        ],
        postCourseShow:0,
        postCourseDetailShow:false,
        studyShow:false,
        kernelCourseArr:[],
        marketForm: {
          name: '',
          phone:'',
          copy:'',
          remark:'',
        },
        rules: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入电话', trigger: 'blur' }
          ],
        },
        courseClassFyDetailtotalPrice:'',
        postTotalPrice:'',
        sortShow:0,
        postCarShow:false
      }
    },
    methods:{
      //判读是否加入购物车
      getcheckUserAddTrolley(id){
        let data={
          id:id
        }
        checkUserAddTrolley(data).then(res=>{
          if(res.code==-10){
            this.postCarShow=true
          }else{
            this.postCarShow=false
          }
        })
      },
      //改变课程排序
      changeSort(index){
        this.sortShow=index;
        this.getCourseList(this.claddFyId)
      },
      //加入购物车
      joinBuyShopping(){
        let courseIds=[]
        this.courseLIstArr.forEach(item=>{
          if(item.whetherPay==false){
            courseIds.push(item.id)
          }
        })
        let dataCourseIds=courseIds.join(',')
        let data={
          goodsPid:this.courseClassFyDetailObj.id,
          goodsType:'COURSE',
          time:'365',
          price:this.postTotalPrice,
          goodsId:dataCourseIds
        }
        addTrolley(data).then(res=>{
          if(res.code === 0){
            if(res.data){
              this.$message({
                message: '成功加入购物车',
                type: 'success'
              });
              this.postCarShow=true
            }else{
              this.$message('已经加入购物车了')
            }
          }
        })
      },
      //点击空白关闭弹窗
      hideMaskPopup(e){
        let mask = document.querySelector(".postPOsition");
        if (mask) {
          if (!mask.contains(e.target)) {//判断所点击的区域是否在.mask_popup中
            this.studyShow = false;
            this.postCourseDetailShow=false;
            this.copyAccounts=false
          }
        }
      },
      //返回顶部
      gotop(){
        document.documentElement.scrollTop=0
      },
      //去订单确认页面
      goOrder(item,index){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if(userInfo&&userInfo.teacherType){
          this.$alert('sorry ,您无购买权限，请联系管理员或以个人账号登录购买', '购买', {
            confirmButtonText: '确定',
            callback: action => {
              this.$message({
                type: 'info',
                message: ''
              });
            }
          });
        }else{
          let routeUrl = this.$router.resolve({
            path: "/courseOrder",
            query:{type:'allTotal',allPrice:'allPrice',classpId:item.id,profiles:item.profiles,classPName:item.name,classProfiles:item.profiles,orderShow:index==0?'0':'1'}
          });
          window.open(routeUrl.href);
          this.studyShow=false
        }

      },
      //提交留言板
      submitCourse(formName){
        let data={
          name:this.marketForm.name,
          phone:this.marketForm.phone,
          companyName:this.marketForm.copy,
          leaveWord:this.marketForm.remark,
          type:'ZCTS'
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$alert('感谢您的留言,我们会尽快联系您', '留言', {
              confirmButtonText: '确定',
              callback: action => {
                this.$message({
                  type: 'info',
                  message:'留言成功'
                });
              }
            });
            this.$message.success('留言成功');
            let that=this
            addLeaveWords(data).then(res=>{
              if(res.code==0){
                // that.$message({
                //   message: "留言成功",
                //   type: "success",
                //   duration: 2000
                // });
                this.postCourseDetailShow=false
              }
            })
          } else {
            return false;
          }
        });

      },
      //关闭悬浮框
      deletePostWindow(){
        this.postCourseDetailShow=false
      },
      //改变悬浮框
      changeCourseIcon(index) {
        if (index == 1) {
          this.postCourseDetailShow = !this.postCourseDetailShow
          this.copyAccounts = false
          this.studyShow = false
          this.courseIconArr[1].img = 'images/marketIcon5.png'
          this.courseIconArr[0].img = 'images/marketIcon1.png'
          this.courseIconArr[2].img = 'images/marketIcon3.png'
        }
        if (index == 0) {
          this.copyAccounts = !this.copyAccounts
          this.postCourseDetailShow = false
          this.studyShow = false
          this.courseIconArr[0].img = 'images/marketIcon4.png'
          this.courseIconArr[1].img = 'images/marketIcon2.png'
          this.courseIconArr[2].img = 'images/marketIcon3.png'
        }
        if (index == 2) {
          this.gotop()
          this.postCourseDetailShow = false
          this.copyAccounts = false
          this.studyShow = false
          this.courseIconArr[2].img = 'images/marketIcon6.png'
          this.courseIconArr[0].img = 'images/marketIcon1.png'
          this.courseIconArr[1].img = 'images/marketIcon2.png'
        }
      },
      //购买vip
      buyCourseVip(){
        this.postCourseDetailShow=false
        this.copyAccounts=false
        this.studyShow=!this.studyShow
        this.courseIconArr[2].img='images/marketIcon3.png'
        this.courseIconArr[0].img='images/marketIcon1.png'
        this.courseIconArr[1].img='images/marketIcon2.png'
      },
    //    tab
      changePostCourseTab(index){
        this.postCourseShows=index
        if(index==0){
          let btn1=document.getElementsByClassName("postCourseTabp")
          btn1[0].style.background="#409EFF"
          btn1[0].style.color="white"
          document.querySelector(".postCourseTabp1").style.background="transparent"
          document.querySelector(".postCourseTabp2").style.background="transparent"
          document.querySelector(".postCourseTabp1").style.color="#FF3B0F"
          document.querySelector(".postCourseTabp2").style.color="#515A6E"
        }else if(index==1){
          document.querySelector(".postCourseTabp1").style.background="#409EFF"
          document.querySelector(".postCourseTabp").style.background="transparent"
          document.querySelector(".postCourseTabp2").style.background="transparent"
          document.querySelector(".postCourseTabp1").style.color="white"
          document.querySelector(".postCourseTabp").style.color="#515A6E"
          document.querySelector(".postCourseTabp2").style.color="#515A6E"
        }else{
          document.querySelector(".postCourseTabp2").style.background="#409EFF"
          document.querySelector(".postCourseTabp").style.background="transparent"
          document.querySelector(".postCourseTabp1").style.background="transparent"
          document.querySelector(".postCourseTabp2").style.color="white"
          document.querySelector(".postCourseTabp1").style.color="#FF3B0F"
          document.querySelector(".postCourseTabp").style.color="#515A6E"
        }
      },
    //  回复
      changeRevert(id){
        // this.revertShow=true;
        this.commentList.forEach((item,index)=>{
          if(item.id==id){
            this.$set(this.commentList[index],  "revertShow",true);
          }
        })
      },
    //  取消回复
      cancleRevert(id){
        this.commentList.forEach((item,index)=>{
          if(item.id==id){
            this.$set(this.commentList[index],  "revertShow",'');
          }
        })
      },
    //  去评论
      goRevert(item){
        this.revertShow=false;
        if(!item.inputContent){
          this.$message.error('评论内容不可为空');return
        }
        let data={
          commentId:item.id,
          replyContent:item.inputContent,
          userType:this.userInfo.teacherType?'2':'1',
          userId:this.userInfo.id,
        };
        createCommentReply(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: "评论成功",
              type: "success",
            });
            this.changeCreateCommentReply(item.id);
            item.inputContent=''
          }else{
            this.$message.error(res.msg)
          }
        })
      },
    //  获取课程分类详情
      getCourseClassFyDetail(claddFyId){
        let data={
          courseClassifyId:claddFyId
        }
        courseClassFyDetail(data).then(res=>{
          if(res.code==0){
           this.courseClassFyDetailObj= res.data
            if(res.data.courseClassifyVos&&res.data.courseClassifyVos.length){
              this.courseClassifyVos.push(res.data.courseClassifyVos[0])
              this.courseClassifyVos.push(res.data.courseClassifyVos[1])
            }

          }
        })
      },
      //  获取课程列表
      getCourseList(claddFyId) {
        let data = {
          page: this.coursePage,
          size: this.courseSize,
          ownerType:'platform',
          courseClassifyId:claddFyId,
          forEnterprise:'1'
        }
        if(this.sortShow==0){
          data.sort='name'
        }else if(this.sortShow==1){
          data.sort='studyPeopleNumber'
        }else if(this.sortShow==2){
          data.sort='createTime'
        }
        courseList(data).then(res => {
          this.courseLIstArr = res.data.records;
          this.courseTotal=JSON.parse(res.data.total);
          let priceTotal=0
          let postTotalPrice=0
          this.courseLIstArr.forEach((item,index)=>{
            if(item.skus&&item.skus.length){
              let price=Math.max.apply(Math,item.skus.map(item1 => { return item1.price }))
              this.$set(this.courseLIstArr[index],  "postPrice",price);
              if(item.whetherPay==false){
                priceTotal+=price
              }
              postTotalPrice+=price
              this.courseClassFyDetailtotalPrice=priceTotal;
              this.postTotalPrice=postTotalPrice
            }else{
              this.courseClassFyDetailtotalPrice=0
            }
          })
        })
      },
    //  去课程详情
      goCourseDetail(item){
        sessionStorage.setItem('courseVideoDetails',JSON.stringify(item) )
        // let routeUrl = this.$router.resolve({
        //   path: "/courseVideoDetais",
        // });
        // window.open(routeUrl.href);
        this.$router.push({path: '/courseVideoDetais', query: {classFyid: this.claddFyId}})
      },
    //  分页
      handleCourseChange(coursePage){
        this.coursePage=coursePage;
        this.getCourseList(this.claddFyId)
      },
    //  评论分页
      handleCommentChange(commentPage){
        this.commentPage=commentPage;
        this.getCommentList(this.claddFyId)
      },
    //  评论列表
      getCommentList(claddFyId){
        let data={
          courseClassifyId:claddFyId,
          page:this.commentPage,
          size:this.commentSize
        };
        commentList(data).then(res=>{
          res.data.records.forEach((item,index)=>{
            this.$set(res.data.records[index],  "inputContent",'');
          })
          this.commentList=res.data.records;
          this.commentTotal=parseInt(res.data.total)
        })
      },
    //  发布评论
      submitComment(){
        if(!this.textarea2){
          this.$message.error('评论内容不可为空');return
        }
        let data={
          courseClassifyId:this.claddFyId,
          userType:this.userInfo.teacherType?'2':'1',
          userId:this.userInfo.id,
          commentContent:this.textarea2
        };
        createComment(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: "评论成功",
              type: "success",
            });
            this.getCommentList(this.claddFyId);
            this.textarea2=''
          }else{
            this.$message.error(res.msg)
          }
        })
      },
    //  评论回复列表
      changeCreateCommentReply(id){
        let data={
          commentId:id
        };
        commentReplyList(data).then(res=>{
          // this.commentReplayList=res.data.records;
          this.commentApllayShow=true;
          this.commentList.forEach((item,index)=>{
            if(item.id==id){
              this.$set(this.commentList[index],  "commentReplayList",res.data.records);
            }
          })
        })
      },
    //  收起
      packUpCommentReply(id){
        this.commentList.forEach((item,index)=>{
          if(item.id==id){
            this.$set(this.commentList[index],  "commentReplayList",'');
          }
        })
      },
      //切换tab
      changeClassfiy(id,item){
        this.courseClassifyVos=[]
        this.table=false
        this.getCourseClassFyDetail(id);
        this.getCourseList(id);
        this.getCommentList(id);
        this.classPid=item.id
        this.classPName=item.name
        this.classProfiles=item.profiles
      },
      //去订单支付
      goBuyCourse(type){
        console.log('type',type)
        if(this.userInfo&&this.userInfo.teacherType){
          this.$alert('sorry ,您无购买权限，请联系管理员或以个人账号登录购买', '购买', {
            confirmButtonText: '确定',
            callback: action => {
              this.$message({
                type: 'info',
                message: ''
              });
            }
          });
        }else{
          let courseId=[]
          this.courseLIstArr.forEach(item=>{
            courseId.push(item.id)
          });
          let courseAllTotal={
            goodsType:'COURSE',
            price:type=='1'?this.courserAllTotal:this.courseClassFyDetailtotalPrice,
            days:'365',
            name:this.courseClassFyDetailObj.name,
            profiles:this.courseClassFyDetailObj.profiles,
            id:this.courseClassFyDetailObj.id,
          }
          if(type=='type'){
            courseAllTotal.courseIds=courseId
            sessionStorage.setItem('courseAllTotal',JSON.stringify(courseAllTotal))
            let routeUrl = this.$router.resolve({
              path: "/courseOrder",
              query:{type:'allTotal',postData:'post'}
            });
            window.open(routeUrl.href);
          }
          if(type=='1'){
            // sessionStorage.setItem('courseAllTotal',JSON.stringify(courseAllTotal))
            let routeUrl = this.$router.resolve({
              path: "/courseOrder",
              query:{type:'allTotal',allPrice:'allPrice',classpId:this.classPid,classPName:this.classPName,classProfiles:this.classProfiles,orderShow:'0'}
            });
            window.open(routeUrl.href);
          }
        }

      },
      //课程分类列表
      getCourseClassify(){
        courseClassify().then(res => {
          this.courseDetailArr=res.data
        })
      },
      //分享链接
      share(){
        // 存储传递过来的数据
        let OrderNumber = document.location.href;
        // 创建一个input 元素
        // createElement() 方法通过指定名称创建一个元素
        let newInput = document.createElement("input");
        // 讲存储的数据赋值给input的value值
        newInput.value = OrderNumber;
        // appendChild() 方法向节点添加最后一个子节点。
        document.body.appendChild(newInput);
        // 选中input元素中的文本
        // select() 方法用于选择该元素中的文本。
        newInput.select();
        // 执行浏览器复制命令
        //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
        document.execCommand("Copy");
        // 清空输入框
        newInput.remove();
        // 下面是element的弹窗 不需要的自行删除就好
        this.$message({
          message: "复制链接成功,请粘贴.",
          type: "success",
        });
      }

    },
    mounted() {
      this.$other.$emit('setIndexNav',4);
      this.classfiyArr=JSON.parse(sessionStorage.getItem('classfiy'))
      // let classObj=JSON.parse(sessionStorage.getItem('classObj'))
      this.claddFyId=this.$route.query.classFyId;
      this.classPid=this.$route.query.classPid;
      this.classPName=this.$route.query.classPName
      this.classProfiles=this.$route.query.classProfiles
      this.getCourseClassify()
      this.getCourseClassFyDetail(this.claddFyId);
      this.getCommentList(this.claddFyId)
      this.getCourseList(this.claddFyId);
      this.getcheckUserAddTrolley(this.claddFyId)
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let btn1=document.getElementsByClassName("postCourseTabp")
      btn1[0].style.background="#409EFF"
      btn1[0].style.color="white"
    }
  }
</script>

<style scoped>
@import "../../../public/css/postCourseDetail.css";
</style>
