<template>
    <div>
<!--        <userHead></userHead>-->
        <el-row v-if="!isCreateOrEdit" style="margin-top: 20px">
            <el-col :span="24">
                <el-card shadow="always">
                    <div slot="header" class="clearfix">
                      <el-row>
                        <el-col :span="20">
                          <el-menu style="width: 100% !important;" :default-active="selfCourseIsOverdue"  mode="horizontal" >
                            <el-menu-item index="0">学习笔记</el-menu-item>
                          </el-menu>
                        </el-col>
                        <el-button style="float: right; " type="primary" @click="createNotes">新建笔记</el-button>
                      </el-row>

                    </div>
                    <el-table
                        :data="tableData"
                        style="width: 100%"
                        :show-header="false"
                        >
                        <el-table-column>
                            <template slot-scope="scope">
                                <el-card shadow="always">
                                    <div slot="header" class="clearfix">
                                        <span style="font-weight:bold">{{scope.row.title}}</span>
                                        <el-button
                                        size="mini"
                                        type="danger"
                                        style="float: right;margin-left:10px;margin-right:10px"
                                        @click="deletedNotes(scope.row.id)"
                                        >删除</el-button>
                                        <el-button
                                        size="mini"
                                        type="primary"
                                        style="float: right;"
                                        @click="editNotes(scope.row)"
                                        >详情</el-button>
                                    </div>
                                    <div class="boxTextClamp" @click="editNotes(scope.row)">
                                        <pre>{{scope.row.mContext}}</pre>
                                    </div>
                                </el-card>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        layout="prev, pager, next"
                        :page-size="pageSize"
                        :total="pageTotal"
                        :current-page="nowCurrent"
                        :current-change="queryUserSelfStudyNotesList">
                    </el-pagination>
                </el-card>
            </el-col>
        </el-row>
        <el-row v-if="isCreateOrEdit">
            <el-col :span="24">

                <el-card shadow="always">
                    <div slot="header" class="clearfix">
                        <span style="font-weight:bold">编辑笔记</span>
                        <el-button style="float: right; width:100px;" type="primary" @click="addOrUpdate">保存</el-button>
                    </div>
                    <div>
                        <el-link type="primary" style="margin-bottom:20px" @click="backStudyNotesList"> > 返回</el-link>
                        <el-input
                        type="textarea"
                        autosize
                        placeholder="请输入笔记标题"
                        v-model="notetitle"></el-input>
                        <el-input
                        style="margin-top:20px"
                        type="textarea"
                        :autosize="{ minRows: 20, maxRows: 20}"
                        placeholder="请输入笔记内容"
                        v-model="noteContext">
                        </el-input>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
//学习笔记
import userHead from './userHead'
import {selfStudyNotesList,selfCreateOrEditStudyNotes,selfDeletedStudyNotes} from '@/api/user'
export default {
    name:"myStudyNotes",
    props: {
        loginUser: Object
    },
    data(){
        return{
            pageSize:10,
            pageTotal:0,
            tableData:[],
            isCreateOrEdit:false,
            noteContext:"",
            notetitle:"",
            selectId:"",
            nowCurrent:1,
            selfCourseIsOverdue:"0",
        }
    },
    created(){
        this.queryUserSelfStudyNotesList(1);
    },
    components:{
      userHead
    },
    computed: {

    },
    updated() {

    },
    methods:{
        queryUserSelfStudyNotesList(current){
            selfStudyNotesList({page:current,size:this.pageSize}).then(res=>{
                if(res.code=="0"&&res.data){
                    this.tableData = res.data.records;
                    this.pageTotal = parseInt(res.data.total);
                }else{
                    this.tableData = [];
                }
            })
            this.nowCurrent = current;
        },
        createNotes(){
            this.noteContext = "";
            this.notetitle = "";
            this.selectId = "";
            this.isCreateOrEdit = true;
        },
        editNotes(row, column){
            this.selectId = row.id;
            this.noteContext = row.mContext;
            this.notetitle = row.title;
            this.isCreateOrEdit = true;
        },
        backStudyNotesList(){
            this.noteContext = "";
            this.notetitle = "";
            this.selectId = "";
            this.isCreateOrEdit = false;
            this.queryUserSelfStudyNotesList(this.nowCurrent);
        },
        addOrUpdate(){
            //校验标题，内容是否合法
            let spNoteContext = this.noteContext;
            let spNotetitle = this.notetitle;
            if(spNoteContext==null||typeof(spNoteContext)=="undefined"||spNoteContext.trim()==""){
                this.$message.error('笔记内容格式错误');
                return;
            }
            if(spNotetitle==null||typeof(spNotetitle)=="undefined"||spNotetitle.trim()==""){
                this.$message.error('笔记标题内容格式错误');
                return;
            }
            let pushData = {
                title:spNotetitle,
                mContext:spNoteContext
            }
            //修改
            if(this.selectId!=""){
                pushData.id = this.selectId;
            }
            selfCreateOrEditStudyNotes(pushData).then(res=>{
                if(res.code=="0"){
                    this.$message({
                    message: '成功',
                    type: 'success'
                    });
                    this.backStudyNotesList();
                }else{
                    this.$message.error('失败,请重试');
                }
            })
        },
        deletedNotes(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                selfDeletedStudyNotes({id:id}).then(res=>{
                    if(res.code=="0"){
                        this.queryUserSelfStudyNotesList(this.nowCurrent);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }else{
                        this.$message.error('失败，请重试');
                    }
                })

            }).catch(() => {

            });
        }
    }
}
</script>
<style scoped>
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .boxTextClamp{
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
  }
pre {
    /* font-family: "微软雅黑"; */
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
