<template>
  <div>
    <div>
      <img src="images/exzApp.svg" style="width: 100%;padding-top: 72px;">
      <div class="shiftApppositon">
        <div class="shiftAppFlex">
          <div @click="changeApp(1)" :class="appbackShow==1?'activeapp':''">
            <img src="images/ios.png">
            <p>iphone版下载</p>
          </div>
          <div @click="changeApp(2)" :class="appbackShow==2?'activeapp':''">
            <img src="images/anzhuo.png">
            <p>Android版下载</p>
          </div>
        </div>
        <div class="shiftCodeIMg"  v-if="appbackShow==1">
          <img src="images/iosCode.png"/>
        </div>
        <div class="shiftCodeIMg"  v-if="appbackShow==2">
          <img src="images/anzhuoCode.png"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        appbackShow:1
      }
    },
    methods:{
      changeApp(index){
        this.appbackShow=index
      }
    },
    mounted() {
      this.$other.$emit('setIndexNav',6);
    }
  }
</script>
<style>
  .shiftApppositon{
    position: absolute;
    top:58%;
    left: 18%;
    display: flex;
    justify-content: space-between;
    /*margin-left: 284px;*/
    width: 380px;
    /*margin-top: 40px;*/
  }
  .shiftAppFlex>div{
    display: flex;
    border: 2px solid #C4FFE6;
    border-radius: 28px;
    padding: 10px 28px;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
  }
  .shiftAppFlex>div>p{
    margin-left: 12px;
  }
  .shiftCodeIMg>img{
    width: 140px;
    height: 140px;
  }
  .activeapp{
    background: #C4FFE6;
  }
</style>
