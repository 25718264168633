<template>
    <div>
<!--        <userHead></userHead>-->
        <!-- 最近学习 -->
        <el-row style="margin-top:20px">
            <el-col :span="24">
                <el-card shadow="always">
                    <div slot="header" >
                        <span style="font-weight:bold">最近学习</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="goToOtherCom('2-1')">更多</el-button>
                    </div>
                    <courseStudyList  :courseListData="courseListData"></courseStudyList>
                </el-card>
            </el-col>
        </el-row>

        <!-- 订单详情 -->
        <el-row v-if="false" style="margin-top:20px">
            <el-col :span="24">
                <el-card shadow="always">
                    <div  >
                        <span style="font-weight:bold">订单详情</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="goToOtherCom('2-1')">更多</el-button>
                    </div>
                    <orderList></orderList>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import courseList from './courseList'
import userHead from './userHead'
import orderList from './orderList.vue'
import courseStudyList from './courseStudyList.vue'
import {selfCourseList,findStudyDays,findPlayResourceTime,studyRecord} from '@/api/user.js'
export default {
    name:"IEXiaoZhi",
    props: {
        loginUser: Object
    },
    data(){
        return{
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            coursePage:1,
            courseSize:3,
            courseListData:[],
            courseTotal:0,
            studyTotalDays:0,
            playResourceTotalTime:0,
        }
    },
    created(){
        this.queryUserSelfCourseList();
        this.queryUserStudyInfo();
    },
    components:{
        courseList,
        orderList,
        userHead,
      courseStudyList
    },
    computed: {

    },
    updated() {

    },
    methods:{
        goToOtherCom(index){
            this.$emit('updateComponent',index)
        },
        queryUserSelfCourseList(){
          studyRecord({page:1,size:20}).then(res=>{
                if(res.code=="0"&&res.data){
                    this.courseListData = res.data.records;
                    if(res.data.total&&res.data.total>0){
                        this.courseTotal = res.data.total;
                    }
                }
            })
        },
        queryUserStudyInfo(){
            findStudyDays().then(res=>{
                if(res.code=="0"&&res.data){
                    let studyDays = res.data;
                    if(studyDays&&typeof(studyDays)!="undefined"){
                        this.studyTotalDays = studyDays;
                    }
                }
            });
            findPlayResourceTime().then(res=>{
                if(res.code=="0"&&res.data){
                    let playResourceTotalTime = res.data;
                    if(playResourceTotalTime&&typeof(playResourceTotalTime)!="undefined"){
                        this.playResourceTotalTime = playResourceTotalTime;
                    }
                }
            })
        },
        s_to_hs(s){
            //计算分钟
            //算法：将秒数除以60，然后下舍入，既得到分钟数
            var h;
            h  =   Math.floor(s/60);
            //计算秒
            //算法：取得秒%60的余数，既得到秒数
            s  =   s%60;
            //将变量转换为字符串
            h    +=    '';
            s    +=    '';
            //如果只有一位数，前面增加一个0
            h  =   (h.length==1)?'0'+h:h;
            s  =   (s.length==1)?'0'+s:s;
            return h+':'+s;
        }
    }
}
</script>

<style>
.exiaozhiNumRadius{
    border-radius:5px;
    background-color: #FDFAF8;
}
.exiaozhiRowFlex{
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*width: 584px;*/
}
.myExiaozhiFont{
  text-align: center;
}
.myExiaozhiFont>p:first-child{
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
  color: #409EFF;
}
</style>
