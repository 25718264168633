<template>
  <div class="accountRelevanceBg">
    <div>
      <div class="accountFlex">
        <div>
          <img src="images/phoneIcon.png"/>
          <p>手机：{{userInfo.telNumber?userInfo.telNumber:''}}</p>
        </div>
        <el-button round @click="amendPhone" v-if="userInfo.telNumber">修改</el-button>
        <el-button round @click="amendPhone" v-if="!userInfo.telNumber">绑定</el-button>
      </div>
      <div class="accountFlex">
        <div>
          <img src="images/wxIcon.png"/>
          <p>微信</p>
        </div>
        <el-button round @click="boundWx" v-if="!userInfo.weixinId">绑定</el-button>
        <el-button round v-if="userInfo.weixinId" @click="unbundleWx">解除绑定</el-button>
      </div>
      <div class="accountFlex">
        <div>
          <img src="images/qqIcon.png"/>
          <p>QQ</p>
        </div>
        <el-button round @click="bindQQ" v-if="!userInfo.qqId">绑定</el-button>
        <el-button round  v-if="userInfo.qqId" @click="unbundleQQ">解除绑定</el-button>
      </div>
    </div>
    <el-dialog
        :visible.sync="phoneWindow"
        :show-close="true"
        title="更换手机号">
      <div class="phoneWindowPadding">
        <div>
          <el-input placeholder="请输入新手机号" v-model="amendPhoneData"></el-input>
        </div>
        <div class="accountCodeFlex">
          <el-input placeholder="请输入验证码" v-model="amendCodeData"></el-input>
          <el-button v-if="regtimeshow" type="primary" @click="getCode">获取验证码</el-button>
          <el-button v-if="!regtimeshow" >{{ count }}s后重新获取</el-button>
        </div>
        <div class="replacementPhone">
          <el-button type="primary" @click="submitPhone">保存</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="wxLogin.showWxReg" width="400px" center :show-close="false">
      <wxlogin :appid="wxLogin.appid" :scope="wxLogin.scope" :state="wxLogin.state" :self_redirect="false" :redirect_uri="wxLogin.redirect_uri"></wxlogin>
    </el-dialog>
  </div>
</template>
<script>
import {
  amendUserPhone, sendPhoneCode,unbundleAccount
} from "@/api";
import wxlogin from "@/components/vue-wxlogin";
  export default {
    components: {
      wxlogin
    },
    data(){
      return{
        wxLogin: {
          showWxReg: false,
          appid:'wx448c5bc5dbbc7c51',
          redirect_uri: 'http%3A%2F%2Fexiaozhi.sdzjds.com%2F',
          scope:'snsapi_login',
          state:"wxLogin"
        },
        phoneWindow:false,
        amendPhoneData:'',
        amendCodeData:'',
        count: "",
        regtimeshow:true,
        userInfo:{}
      }
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    },
    methods:{
      amendPhone(){
        this.phoneWindow=true
      },
    //  获取验证码
      getCode(){
        if (!/^[1]([0-9])[0-9]{9}$/.test(this.amendPhoneData)) {
          this.$message.error("手机格式不对");
          return;
        }
        let TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.regtimeshow = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.regtimeshow = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        };
        let data={
          phone:this.amendPhoneData
        };
        sendPhoneCode(data).then(res=>{
          if(res.code!==0){
            this.$message.error(res.msg)
          }
        })
      },
    //  保存
      submitPhone(){
        let data={
          telNumber:this.amendPhoneData,
          verifyCode:this.amendCodeData,
          terminalType:'WEB'
        };
        amendUserPhone(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: "成功",
              type: "success",
            });
            this.phoneWindow=false;
            this.$cookie.set("userToken", res.data.loginToken);
            localStorage.setItem('userInfo',JSON.stringify(res.data));
          }else{
            this.$message.error(res.msg)
          }
        })
      },
    //  绑定微信
      boundWx(){
        sessionStorage.setItem("bound", 'bound');
        this.wxLogin.showWxReg = true;
      },
    //  绑定QQ
      bindQQ(){
        let url = this.$util.getQQLoginUrl({});
        window.location.href = url;
        sessionStorage.setItem('bindQQUiconID','bindQQUiconID')
      },
    //  解绑微信
      unbundleWx(){
        let data={
          weixinId:this.userInfo.weixinId,
          terminalType:'WEB'
        };
        unbundleAccount(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: "解绑成功",
              type: "success",
            });
            localStorage.setItem('userInfo',JSON.stringify(res.data));
            this.$cookie.set("userToken", res.data.loginToken);
          }else{
            this.$message.error(res.msg)
          }
        })
      },
    //  解绑QQ
      unbundleQQ(){
        let data={
          qqId:this.userInfo.qqId,
          terminalType:'WEB'
        };
        unbundleAccount(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: "解绑成功",
              type: "success",
            });
            localStorage.setItem('userInfo',JSON.stringify(res.data));
            this.$cookie.set("userToken", res.data.loginToken);
          }else{
            this.$message.error(res.msg)
          }
        })
      }
    }
  }
</script>
<style scoped>
.accountRelevanceBg{
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  padding: 24px;
  border-radius: 6px;
  background: white;
}
.accountFlex,.accountFlex>div{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accountFlex>div>img{
  margin-right: 12px;
}
.accountFlex{
  border-bottom: 1px solid #919191;
  padding-bottom: 20px;
  margin: 20px 0;
}
.accountCodeFlex{
  display: flex;
  align-items: center;
}
.phoneWindowPadding{
  padding: 12px 160px;
}
.phoneWindowPadding>div{
  margin-bottom: 18px;
  text-align: center;
}
.accountCodeFlex>button{
  margin-left: 10px;
}
.accountRelevanceBg .el-button--primary{
  width: 62%;
}
</style>
