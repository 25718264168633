<template>
    <div>
<!--        <userHead></userHead>-->
        <el-card shadow="always" style="margin-top: 20px">
            <div slot="header" >
                <el-row>
                    <el-col :span="20">
                        <el-menu style="width: 100% !important;" :default-active="selfCourseIsOverdue"  mode="horizontal" @select="selectOpen">
                            <el-menu-item index="0">我的课程</el-menu-item>
                            <el-menu-item index="1">已过期课程</el-menu-item>
                        </el-menu>
                    </el-col>
                </el-row>
            </div>
            <div>
                <el-row>
                    <el-col :span="24">
                        <courseList :courseListData="courseListData"></courseList>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                      <el-pagination
                          class="text_center"
                          background
                          @current-change="queryUserSelfCourseList"
                          :current-page.sync="classPage"
                          :page-size="pageSize"
                          layout="total,  prev, pager, next, jumper"
                          :total="pageTotal"
                      >
                      </el-pagination>
                    </el-col>
                </el-row>

            </div>
        </el-card>
    </div>
</template>
<script>
import courseList from './courseList'
import {selfCourseList} from '@/api/user.js'
import userHead from './userHead'
export default {
    name:"myCourseList",
    data(){
        return{
            //是否过期，0否1是
            selfCourseIsOverdue:"0",
            courseListData:[],
            pageNum:1,
            pageSize:10,
            pageTotal:0,
            classPage:1
        }
    },
    created(){
        this.queryUserSelfCourseList(1);
    },
    components:{
        courseList,
        userHead
    },
    computed: {

    },
    updated() {

    },
    methods:{
        selectOpen(index, indexPath){
            this.selfCourseIsOverdue = index;
            this.queryUserSelfCourseList(1);
        },
        queryUserSelfCourseList(current){
          this.classPage = current;
          this.getCourseList()
        },
        getCourseList(){
          selfCourseList({page:this.classPage,size:this.pageSize,isOverdue:this.selfCourseIsOverdue}).then(res=>{
            if(res.code=="0"&&res.data){
              this.courseListData = res.data.records;
              this.pageTotal = parseInt(res.data.total);
            }else{
              this.courseListData = [];
              this.pageTotal = 0;
            }
          })
        }
    }
}
</script>
