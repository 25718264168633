<template>
    <div>
        <el-card shadow="always">
            <div style="text-align:center">
                <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                action=""
                :http-request="uploadUserAvatar"
                :before-upload="beforeAvatarUpload">
                    <el-avatar v-if="copyLoginUserData.headPortraitUrl" :size="200" :src="copyLoginUserData.headPortraitUrl">
                        <img :src="copyLoginUserData.headPortraitUrl"/>
                    </el-avatar>
                    <el-avatar v-else :size="100" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
                </el-upload>

            </div>
            <div>
                <el-row>
                    <el-col :span="12">
                        <el-form label-position="right" label-width="80px" >
                            <el-form-item label="账号">
                                <span>{{copyLoginUserData.account}}</span>
                            </el-form-item>
                          <!-- 真实姓名 -->
                            <el-form-item label="姓名">
                                <el-input v-if="isUpdateSelfInfo" v-model="copyLoginUserData.name" placeholder="请输入内容"></el-input>
                                <span v-if="!isUpdateSelfInfo" >{{copyLoginUserData.name}}</span>
                            </el-form-item>
                            <el-form-item label="昵称">
                                <el-input v-if="isUpdateSelfInfo" v-model="copyLoginUserData.nickName" placeholder="请输入内容"></el-input>
                                <span v-if="!isUpdateSelfInfo" >{{copyLoginUserData.nickName}}</span>
                            </el-form-item>
                            <el-form-item label="性别">
                                <el-select v-if="isUpdateSelfInfo" v-model="copyLoginUserData.gender" placeholder="请选择">
                                    <el-option
                                    v-for="item in genderOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                                <span v-if="!isUpdateSelfInfo" >{{copyLoginUserData.gender=='male'?'男':copyLoginUserData.gender=='female'?'女':''}}</span>
                            </el-form-item>
                            <el-form-item label="专业">
                                <el-input v-if="isUpdateSelfInfo" v-model="copyLoginUserData.majorName" placeholder="请输入内容"></el-input>
                                <span v-if="!isUpdateSelfInfo" >{{copyLoginUserData.majorName}}</span>
                            </el-form-item>
                            <el-form-item label="学历">
                                <el-input v-if="isUpdateSelfInfo" v-model="copyLoginUserData.education" placeholder="请输入内容"></el-input>
                                <span v-if="!isUpdateSelfInfo" >{{copyLoginUserData.education}}</span>
                            </el-form-item>
                            <el-form-item label="城市">
                                <el-cascader
                                    v-model="pcaValue"
                                    :options="options"
                                    :props="{ expandTrigger: 'hover' }"
                                    @change="selectUserRegion"
                                    ></el-cascader>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="12">
                        <el-form label-position="right" label-width="80px" >
                            <el-form-item label="签名">
                                <el-input v-if="isUpdateSelfInfo" v-model="copyLoginUserData.personalizedSignature" placeholder="请输入内容"></el-input>
                                <span v-if="!isUpdateSelfInfo" >{{copyLoginUserData.personalizedSignature}}</span>
                            </el-form-item>
                            <el-form-item label="生日">
                                <el-date-picker
                                v-if="isUpdateSelfInfo"
                                v-model="copyLoginUserData.birthday"
                                type="date"
                                placeholder="选择日期"
                                format="yyyy 年 MM 月 dd 日">
                                </el-date-picker>
                                <span v-if="!isUpdateSelfInfo" >{{copyLoginUserData.birthday}}</span>
                            </el-form-item>
                            <el-form-item label="院校">
                                <el-input v-if="isUpdateSelfInfo" v-model="copyLoginUserData.schoolName" placeholder="请输入内容"></el-input>
                                <span v-if="!isUpdateSelfInfo" >{{copyLoginUserData.schoolName}}</span>
                            </el-form-item>
                            <el-form-item label="职业">
                                <el-input v-if="isUpdateSelfInfo" v-model="copyLoginUserData.careerName" placeholder="请输入内容"></el-input>
                                <span v-if="!isUpdateSelfInfo" >{{copyLoginUserData.careerName}}</span>
                            </el-form-item>
                            <el-form-item label="行业">

                                <el-input v-if="isUpdateSelfInfo" v-model="copyLoginUserData.industryName" placeholder="请输入内容"></el-input>
                                <span v-if="!isUpdateSelfInfo" >{{copyLoginUserData.industryName}}</span>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
            </div>
            <div>
                <el-button v-if="!isUpdateSelfInfo" style="float:right;margin-bottom:20px" class="btnStyle" @click="goToUpdateSelfInfo">修改</el-button>
                <el-button v-if="isUpdateSelfInfo" style="float:right;margin-bottom:20px" class="btnStyle" @click="editUserInfo">保存</el-button>
                <el-button v-if="isUpdateSelfInfo" style="float:right;margin-right:20px;margin-bottom:20px" class="btnStyle" @click="backUpdateSelfInfo">返回</el-button>
            </div>
        </el-card>
    </div>
</template>
<script>
import {getData,findLabelByValue,findValueByLable} from "@/utils/pmdJson"
import {editUserBaseInfo,uploadUserAvatar} from '@/api/user.js'
export default {
    name:"selfInfo",
    props: {
        loginUser: Object
    },
    data(){
        return{
            copyLoginUserData:{},
            pcaValue:"",
            options:getData(),
            isUpdateSelfInfo:false,
            genderOptions:[
                {
                    value: 'male',
                    label: '男'
                },
                {
                    value: 'female',
                    label: '女'
                }
            ],
            showProvinceName:"",
            showCityName:"",
            showCountyName:"",
        }
    },
    created(){
        this.initData(this.loginUser);
    },
    components:{

    },
    computed: {

    },
    updated() {

    },
    methods:{
        test(){
            let nodeAry = findLabelByValue(this.pcaValue);
            let node2Ary = findValueByLable(["山东省","济南市","历下区"])
            let newPcaValue = [];
            node2Ary.forEach(element => {
                newPcaValue.push(element.value)
            });
            this.pcaValue = newPcaValue;
        },
        selectUserRegion(selectData){
            if(this.isUpdateSelfInfo){
                this.pcaValue = selectData;
            }
        },
        initData(data){
            this.copyLoginUserData = Object.assign({},data)
            if(this.copyLoginUserData.gender=="unknown"){
                this.copyLoginUserData.gender="male"
            }
            //根据所属地区名称，回显
            let provinceName = this.copyLoginUserData.provinceName;
            let cityName = this.copyLoginUserData.cityName;
            let countyName = this.copyLoginUserData.countyName;
            if(countyName&&countyName!=null&&typeof(countyName)!="undefined"&&countyName!=''){
                let lableAry = [provinceName,cityName,countyName]
                let valueAry = findValueByLable(lableAry);
                let newPcaValue = [];
                valueAry.forEach(va=>{
                    newPcaValue.push(va.value)
                })
                this.pcaValue = newPcaValue;
                this.showProvinceName = provinceName;
                this.showCityName = cityName;
                this.showCountyName = countyName;
            }
        },
        goToUpdateSelfInfo(){
            this.isUpdateSelfInfo = true;
        },
        backUpdateSelfInfo(){
            this.isUpdateSelfInfo = false;
        },
        editUserInfo(){
            if(this.pcaValue!=""||this.pcaValue.length>0){
                let nodeAry = findLabelByValue(this.pcaValue);
                let provinceName = nodeAry[0].label;
                let cityName = nodeAry[1].label;
                let countyName = nodeAry[2].label;
                this.copyLoginUserData.provinceName = provinceName;
                this.copyLoginUserData.cityName = cityName;
                this.copyLoginUserData.countyName = countyName;
            }
            editUserBaseInfo(this.copyLoginUserData).then(res=>{
                if(res.code=="0"){
                    this.$message({
                    message: '操作成功',
                    type: 'success'
                    });
                    this.isUpdateSelfInfo = false;
                    this.initData(this.copyLoginUserData);
                    //更新用户缓存
                    this.$emit("updateUserCacheInfo",this.copyLoginUserData)
                }else{
                    this.$message.error('失败，请重试');
                    this.isUpdateSelfInfo = false;
                }
            })
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        uploadUserAvatar(fileInfo){
            let form = new FormData();
            console.log(fileInfo)
            form.append("file", fileInfo.file);
            form.append("bizType", "headPortrait");
            //aliVideoOssData
            form.append("aliVideoOssData", "0");
            uploadUserAvatar(form).then(res=>{
                if(res.code=="0"&&res.data){
                    let avatarUrl = res.data.url
                    if(avatarUrl&&avatarUrl!=null&&typeof(avatarUrl)!="undefined"&&avatarUrl!=''){
                        editUserBaseInfo({id:this.copyLoginUserData.id,headPortraitUrl:avatarUrl}).then(res=>{
                            if(res.code=="0"){
                                this.copyLoginUserData.headPortraitUrl = avatarUrl;
                                this.$message({
                                message: '操作成功',
                                type: 'success'
                                });
                                //更新用户信息缓存
                                this.$emit("updateUserCacheInfo",this.copyLoginUserData)
                            }else{
                                this.$message.error('失败，请重试');
                            }
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.btnStyle{
    background-image: linear-gradient(to right,#409EFF ,#409EFF);
    color: white;
    cursor: pointer;
    border-radius: 8px;
}
.clearfix:before,
.clearfix:after {
display: table;
content: "";
}
.clearfix:after {
clear: both
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
