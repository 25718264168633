<template>
  <div class="teacherDetailBg">
    <div class="teacherDetailImgBg">
      <div >
        <img :src="teacherDetail.logo?teacherDetail.logo:'images/tacitlyAvatar.png'" class="teacherIMg"/>
        <div class="teacherDetailContent">
          <p>{{teacherDetail.name}}</p>
          <p>{{teacherDetail.position}}</p>
          <p>{{teacherDetail.profiles}}</p>
        </div>
      </div>
    </div>
<!--    <div class="teacherDetailContentBg">-->
<!--      <div class="streamingCourse">-->
<!--        <p v-for="(item,index) in teacherCourseTab" :key="index" class="streamingCoursep" :class="teacherCourseShow==index?'teacherCourseActive':''" @click="changeteacherTab(index)">{{item.name}}</p>-->
<!--      </div>-->
<!--    </div>-->
    <div>
      <div class="teacherDetailInstruce">
<!--        <h3 v-if="teacherCourseShow==0">直播</h3>-->
<!--        <h3 v-if="teacherCourseShow==1">课程</h3>-->
        <div v-if="teacherCourseLIstArr.length" class="teacherDetailContenFelx" v-for="(item,index) in teacherCourseLIstArr" :key="index">
          <div>
            <img :src="item.logo?item.logo:'images/zanwuImgs.png'"/>
            <div class="teacherCourseContent">
              <h3>{{item.name}}</h3>
              <p>
                <span>主讲教师:{{item.teacherUser.name?item.teacherUser.name:item.teacherUser.account}}</span>
<!--                <span>直播时间：2021年5月27日  15：30—17:00</span>-->
              </p>
            </div>
          </div>
          <div class="teacherDetaiBtn">
            <p @click="goCourseDetail(item)">课程详情</p>
          </div>
        </div>
        <div v-if="!teacherCourseLIstArr.length">
          <el-empty :image-size="200"></el-empty>
        </div>
        <div style="margin: 30px 0">
          <el-pagination
              class="text_center"
              background
              @current-change="handleCourseListChange"
              :current-page.sync="coursePage"
              :page-size="courseSize"
              layout="total,  prev, pager, next, jumper"
              :total="courseTotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {courseList} from "@/api";
  export default{
    data(){
      return{
        teacherCourseTab:[
          {id:1,name:'直播'},
          {id:2,name:'课程'},
        ],
        teacherCourseShow:0,
        teacherDetail:{},
        coursePage:1,
        courseSize:10,
        courseTotal:0,
        teacherCourseLIstArr:[],
        teacherId:""
      }
    },
    methods:{
      changeteacherTab(index){
        this.teacherCourseShow=index
      },
      //获取教师课程列表
      getTeacherCourse(){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
          page:this.coursePage,
          size:this.courseSize,
          ownerId:this.teacherId,
          queryMustSku:true,
          containTeacherAccounts:this.teacherDetail.account
        }
        courseList(data).then(res=>{
          this.teacherCourseLIstArr=res.data.records;
          this.courseTotal=JSON.parse(res.data.total)
        })
      },
    //  课程列表分页
      handleCourseListChange(coursePage){
        this.coursePage = coursePage;
        this.getTeacherCourse();
      },
    //  去课程详情页面
      goCourseDetail(item){
        sessionStorage.setItem('courseDetail',JSON.stringify(item) );
        let routeUrl = this.$router.resolve({
          path: "/courseDetail",
          query:{
            myCourseDetail:'myCourseDetail',
            count:item.courseItemList.length
          }
        });
        window.open(routeUrl.href);
      }
    },
    mounted(){
      this.teacherId=this.$route.query.id
      this.teacherDetail=JSON.parse(sessionStorage.getItem('teacherDetail'))
      this.getTeacherCourse();
    }
  }
</script>
<style>
 @import "../../../public/css/teacherDetail.css";
</style>
