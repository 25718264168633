<template>
    <div>
<!--        <userHead></userHead>-->
        <el-card shadow="always" style="margin-top: 20px">
            <div slot="header" >
                <el-row>
                    <el-col :span="20">
                        <el-menu style="width: 100% !important;" :default-active="selfCourseIsOverdue"  mode="horizontal" @select="selectOpen">
                            <el-menu-item index="0">我收藏的课程</el-menu-item>
                        </el-menu>
                    </el-col>
                </el-row>
            </div>
            <div>
                <el-row>
                    <el-col :span="24">
                        <collectionCourseList :courseListData="courseListData" :isCollectionList="true" @refreshDataFunc="queryUserCollectionCourseList"></collectionCourseList>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                      <el-pagination
                          class="text_center"
                          background
                          @current-change="queryUserCollectionCourseList"
                          :current-page.sync="classPage"
                          :page-size="pageSize"
                          layout="total,  prev, pager, next, jumper"
                          :total="pageTotal"
                      >
                      </el-pagination>
                    </el-col>
                </el-row>

            </div>
        </el-card>
    </div>
</template>
<script>
import collectionCourseList from './courseList'
import {selfCourseList} from '@/api/user.js'
import {courseList,queryAndCacheUserCollectionCourseList} from '@/api/index.js'
import {selectCollectCourceId} from "@/api";
import userHead from './userHead'
export default {
    name:"myCourseList",
    data(){
        return{
            //是否过期，0否1是
            selfCourseIsOverdue:"0",
            courseListData:[],
            pageNum:1,
            pageSize:10,
            pageTotal:0,
            classPage:1
        }
    },
    created(){
        this.queryUserCollectionCourseList(1);
    },
    components:{
        collectionCourseList,
        userHead
    },
    computed: {

    },
    updated() {

    },
    methods:{
      selectOpen(index, indexPath){
          this.selfCourseIsOverdue = index;
          this.queryUserCollectionCourseList(1);
      },
      queryUserCollectionCourseList(current){
        this.classPage = current;
         this.getCollectionCourseList()
      },
      getCollectionCourseList(){
        let query={
          page:this.classPage,
          size:this.pageSize
        }
        selectCollectCourceId(query).then(res=>{
          this.courseListData=res.data.records;
          this.pageTotal = parseInt(res.data.total);
        })
      }
    }
}
</script>
