<template>
    <div>
<!--        <userHead></userHead>-->
        <el-card shadow="always" style="margin-top: 20px">
            <el-row>
                <el-col :span="20">
                    <el-menu style="width: 100% !important;" :default-active="defaultOpenActiveMenu"  mode="horizontal" @select="selectOpen">
                        <el-menu-item index="1">个人信息</el-menu-item>
<!--                        <el-menu-item index="2">荣誉墙</el-menu-item>-->
                        <el-menu-item index="3">账户关联</el-menu-item>
                        <el-menu-item index="4">修改账户</el-menu-item>
                    </el-menu>
                </el-col>
                <el-col :span="4">
                        <!-- <el-button style="float: right;background-color:#DE3638;border-radius: 8px;color:white">考勤签到</el-button> -->
                </el-col>
            </el-row>
        </el-card>

        <el-row style="margin-top:20px">
            <el-col :span="24" v-if="defaultOpenActiveMenu=='1'">
                <component :is="isComponent"  :loginUser="loginUser" @updateUserCacheInfo="updateUserCacheInfo"/>
            </el-col>
            <el-col :span="24" v-if="defaultOpenActiveMenu=='3'">
                <accountRelevance></accountRelevance>
            </el-col>
          <el-col :span="24" v-if="defaultOpenActiveMenu=='4'">
            <amendAccount></amendAccount>
          </el-col>
        </el-row>
    </div>
</template>
<script>
import userHead from './userHead'
import selfInfo from './selfCenter/selfInfo';
import accountRelevance from './selfCenter/accountRelevance'
import amendAccount from './selfCenter/amendAccount'
export default {
    name:"selfCenter",
    props: {
        loginUser: Object
    },
    data(){
        return{
            defaultOpenActiveMenu:"1",
            isComponent: '',
        }
    },
    created(){
        this.selectOpen("1");
    },
    components:{
        selfInfo,
      accountRelevance,
      amendAccount,
      userHead
    },
    computed: {

    },
    updated() {

    },
    methods:{
        selectOpen(index, indexPath){
            let map = {
                '1':'selfInfo',  // 个人信息
                '2':'accountRelevance',  // 账户关联
                '3':'amendAccount',  // 修改账户
            }
            this.isComponent = map[index];
            this.defaultOpenActiveMenu = index;
        },
        updateUserCacheInfo(newUserData){
            this.$emit("updateUserInfo",newUserData)
        }
    }
}
</script>
