<template>
  <div class="addExaminationPaperBg">
    <div class="addExaminationPaperPad">
      <div class="addExaminationPaperHeader">
        <el-steps  :active="addActive" finish-status="success">
          <el-step title="基本信息"></el-step>
          <el-step title="选择试题"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>
      </div>
      <div v-if="addExaminationOne" class="addExaminationPaperContent">
        <div>
          <div class="addExaminationPaperFlex">
            <div>
              <p class="addExaminationTitle">试卷信息：</p>
              <div class="addExaminationPaperMR">
                <p>
                  <span>试卷标题</span>
                  <el-input placeholder="请输入试卷标题" v-model="examinationData.name"></el-input>
                </p>
                <p v-if="!vocationalShow">
                  <span>所属课程</span>
                  <el-select v-model="examinationData.platformCourseId" placeholder="请选择" @change="changeCourse()">
                    <el-option
                        v-for="item in schoolCourseLIstArr"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </p>
                <p v-if="vocationalShow">
                  <span>所属课程</span>
                  <el-cascader
                      v-model="examinationData.platformCourseId"
                      :options="majorListArr"
                      :props="{ multiple: true, checkStrictly: true}"
                      clearable
                  @change="changeCas"></el-cascader>
                </p>
              </div>
            </div>
<!--            <p class="amendNext" @click="amendExamination()" v-if="amendexAminationId">提交修改</p>-->
            <p class="saveNext" @click="preserveNext" >保存进入下一步</p>
          </div>
          <div class="addExaminationPaperdescribe">
            <p class="addExaminationTitle">试卷描述：</p>
            <div class="addExaminationPaperMR">
              <div class="uploadQuestionAvartar">
                <custom-upload v-model="examinationData.logo"  bizType='examinationType' aliVideoOssData="1"></custom-upload>
              </div>
              <p>
                <span>关键字</span>
                <el-input v-model="examinationData.keyWords" placeholder="请输入关键字"></el-input>
              </p>
              <p>
                <span>描述</span>
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="请输入内容"
                    v-model="examinationData.papersDescribe">
                </el-input>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="addExaminationTwo" class="addExaminationTwoContent">
        <div>
          <div class="addExaminationPaperStep">
            <p>添加试题到试卷：《{{examinationData.name}}》</p>
            <div style="margin-bottom: 20px">
              <span @click="upStep">上一步</span>
              <span @click="lastStep()">保存进入下一步</span>
            </div>
          </div>
          <el-card >
            <div class="compositionWayDiv" v-if="!this.templateDetailId">
              <p class="questionWay">组卷方式: </p>
              <div style="display: flex">
                <el-radio v-model="compositionShow" label="1" border size="small">手动组卷</el-radio>
                <p class="randomqueBorder" @click="changeRandiom">
                  <span></span>
                  <span>快速组卷</span>
                </p>
              </div>
            </div>
            <div class="compositionWayDiv" >
              <p class="questionWay">选题方式: </p>
              <div>
                <el-radio v-model="compositonWayShow" label="1" border size="small">固定选题</el-radio>
                <el-radio v-model="compositonWayShow" label="2" border size="small">随机选题</el-radio>
              </div>
            </div>
          </el-card>
          <div class="addExaminationPaperL" @click="addExaminationWindow" v-if="newQuestion">
            <p class="addExam" >添加试题</p>
          </div>
          <div class="addExaminationPaperM" v-if="!newQuestion" >
            <div class="addnewsBtnFlex">
              <p class="newaddBnt" v-if=" chooseQuestionList && chooseQuestionList.length" @click="addNewsQuestion">新增试题</p>
              <p v-else class="newaddBnt" @click="addExaminationWindow">添加试题</p>
              <p class="newaddBnt" @click="deleteExaminationDetail()" v-if=" chooseQuestionList && chooseQuestionList.length">删除试卷详情</p>
            </div>
<!--            新增区域-->
            <div class="selectedRegion" v-if="newsChooseQuestionList.length&&templateDetailId">
              <el-tag >
                新增区域
              </el-tag>
            </div>
            <div v-if="newsChooseQuestionList.length&&templateDetailId" style="background: #f7f7f7">
              <div  class="topicOveflow" v-for="(item,index) in newsChooseQuestionList" :key="index" >
                <div class="questionTopicBorders">
                  <p class="topicTitle" >
                    <span>{{ index + 1 }}.</span>
                    <el-input @input="changeNewScore(index,item)" v-model="item.newScoreNumber" :value="item.newScoreNumber"></el-input>
                    <span>(分)</span>
                    <span class="exercisesTitleWidth">{{ item.exercisesTitle }}</span>
                  </p>
                  <div>
                    <!-- 选择题-->
                    <div v-if="item.exercisesType=='SC' || item.exercisesType=='MC'">
                      <div v-for="(item1, index1) in item.questionOption"
                           :key="index1"
                           class="chooseSpan">
                        <div >
                          {{getLetter(index1)}}：{{ item1 }}
                        </div>
                      </div>
                    </div>
                    <!-- 判断题-->
                    <div v-if="item.exercisesType=='TF'">
                      <el-radio v-model="chooseRadio" disabled label="选中且禁用">正确</el-radio>
                      <el-radio v-model="chooseRadio" label="2" disabled >错误</el-radio>
                    </div>
                    <!-- 简答题-->
                    <div v-if="item.exercisesType=='SAQ'">
                      <el-input
                          type="textarea"
                          disabled
                          :autosize="{ minRows: 4, maxRows: 10 }"
                      ></el-input>
                    </div>
                  </div>
                </div>
                <img src="images/deleteQustion.png" class="deleteQuestionIMg" @click="deletAmendNewChooseQuestion(index,item)"/>
              </div>
            </div>
<!--            已选区域-->
            <div class="selectedRegion">
              <el-tag >
                已选区域
              </el-tag>
            </div>
            <div  class="topicOveflow" v-for="(item,index) in chooseQuestionList" :key="index" >
              <div class="questionTopicBorders">
                <p class="topicTitle" >
                  <span>{{ index + 1 }}.</span>
                  <el-input @input="changeScore(index,item)" v-model="item.newScoreNumber" :value="item.newScoreNumber"></el-input>
                  <span>(分)</span>
                  <span class="exercisesTitleWidth">{{ item.exercisesTitle }}</span>
                </p>
                <div>
                  <!-- 选择题-->
                  <div v-if="item.exercisesType=='SC' || item.exercisesType=='MC'">
                    <div v-for="(item1, index1) in item.questionOption"
                         :key="index1"
                         class="chooseSpan">
                      <div >
                        {{getLetter(index1)}}：{{ item1 }}
                      </div>
                    </div>
                  </div>
                  <!-- 判断题-->
                  <div v-if="item.exercisesType=='TF'">
                    <el-radio v-model="chooseRadio" disabled label="选中且禁用">正确</el-radio>
                    <el-radio v-model="chooseRadio" label="2" disabled >错误</el-radio>
                  </div>
                  <!-- 简答题-->
                  <div v-if="item.exercisesType=='SAQ'">
                    <el-input
                        type="textarea"
                        disabled
                        :autosize="{ minRows: 4, maxRows: 10 }"
                    ></el-input>
                  </div>
                </div>
              </div>
              <img src="images/deleteQustion.png" class="deleteQuestionIMg" @click="deleteChooseQuestion(index,item)"/>
            </div>
            <div style="margin: 30px 0" v-if="templateDetailId">
              <el-pagination
                  class="text_center"
                  background
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-size="pagesize"
                  layout="total,  prev, pager, next, jumper"
                  :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
<!--        答题时长-->
        <div class="examationEchartsWd">
          <div >
            <div class="questionScore">
              <p>单选题</p>
              <p>
                <span>共</span>
                <span class="spanTag">{{exercisesTypeSize.exercisesSC?exercisesTypeSize.exercisesSC:0}}</span>
                <span>题, 共</span>
                <span class="spanTag">{{scoreTotal.scoreScTotal}}</span>
                <span>分</span>
              </p>
            </div>
            <div class="questionScore" v-if="subjectType==1">
              <p>多选题</p>
              <p>
                <span>共</span>
                <span class="spanTag">{{exercisesTypeSize.exercisesMC?exercisesTypeSize.exercisesMC:0}}</span>
                <span>题, 共</span>
                <span class="spanTag">{{scoreTotal.scoreMcTotal}}</span>
                <span>分</span>
              </p>
            </div>
            <div class="questionScore" v-if="subjectType==1">
              <p>填空题</p>
              <p>
                <span>共</span>
                <span class="spanTag">{{exercisesTypeSize.exercisesFB?exercisesTypeSize.exercisesFB:0}}</span>
                <span>题, 共</span>
                <span class="spanTag">{{scoreTotal.scoreFBTotal}}</span>
                <span>分</span>
              </p>
            </div>
            <div class="questionScore" v-if="subjectType==1">
              <p>判断题</p>
              <p>
                <span>共</span>
                <span class="spanTag">{{exercisesTypeSize.exercisesTF?exercisesTypeSize.exercisesTF:0}}</span>
                <span>题, 共</span>
                <span class="spanTag">{{scoreTotal.scoreTFTotal}}</span>
                <span>分</span>
              </p>
            </div>
            <div class="questionScore">
              <p>简答题</p>
              <p>
                <span>共</span>
                <span class="spanTag">{{exercisesTypeSize.exercisesSAQ?exercisesTypeSize.exercisesSAQ:0}}</span>
                <span>题, 共</span>
                <span class="spanTag">{{scoreTotal.scoreSAQTotal}}</span>
                <span>分</span>
              </p>
            </div>
            <div class="questionScore">
              <p>{{subjectType==1?'阅读理解':'综合分析题'}}</p>
              <p>
                <span>共</span>
                <span class="spanTag">{{exercisesTypeSize.exercisesRC?exercisesTypeSize.exercisesRC:0}}</span>
                <span>题, 共</span>
                <span class="spanTag">{{scoreTotal.scoreRCTotal}}</span>
                <span>分</span>
              </p>
            </div>
            <div class="questionScore" v-if="subjectType==1">
              <p>完形填空</p>
              <p>
                <span>共</span>
                <span class="spanTag">{{exercisesTypeSize.exercisesCT?exercisesTypeSize.exercisesCT:0}}</span>
                <span>题, 共</span>
                <span class="spanTag">{{scoreTotal.scoreCTTotal}}</span>
                <span>分</span>
              </p>
            </div>
            <div class="questionScoremar">
              <p>
                <span>总题数</span>
                <span class="spanTag">{{totalExercisesNumber?totalExercisesNumber:chooseQuestionList.length}}</span>
                <span>题</span>
              </p>
              <p>
                <span>当前总分</span>
                <span class="spanTag">{{scoreCom}}</span>
                <span>分</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="addExaminationThree" class="addExaminationThreeoContent">
        <div>
          <p>《{{examinationData.name}}》</p>
          <img src="images/success.png" />
          <p>创建试卷成功</p>
          <p>试卷处于草稿状态，发布分配学生后方可进行考试</p>
          <p>
            <span @click="goExamination">确定</span>
            <span @click="gopublishExamintion">发布</span>
          </p>
        </div>
      </div>
    </div>
<!--    添加试题弹窗-->
    <el-dialog :visible.sync="ExaminationWindowShow" :show-close="true" width="76%">
      <div>
        <div class="examinationFlexsd">
          <div v-if="!vocationalShow">
            <el-menu
                default-active="1"
                class="el-menu-vertical-demo"

            >
              <el-submenu v-for="(item, index) in courseDetailArr"
                          :key="index" :index="index + 1 + ''">
                <template slot="title">
                  <span class="courseZjWid">{{ item.name }}</span>
                </template>
                <el-menu-item-group
                    v-for="(itemChildren, indexChildren) in item.children"
                    :key="indexChildren"
                >
                  <el-menu-item :index="index + '-' + indexChildren">
                    <div slot="title" @click="chooseChilrden(itemChildren)">
                      <span class="jieTitle" >{{ itemChildren.name }}</span>
                    </div>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>
          <div v-if="vocationalShow" class="vocationalPadding">
            <el-tree
                :data="chapterData"
                :props="defaultProps"
                accordion
                @node-click="handleNodeClick">
            </el-tree>
          </div>
<!--          试题-->
          <div>
            <div class="questionsFlexSelect">
              <div v-if="!vocationalShow">
                <el-select v-model="questionsTypeValue" placeholder="请选择题目类型">
                  <el-option
                      v-for="item in questionsTypeArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                  </el-option>
                </el-select>
              </div>
              <div  v-if="vocationalShow">
                <el-select v-model="questionsTypeValue">
                  <el-option
                      v-for="item in synthesizeQuestionsTypeArr"
                     :key="item.id"
                     :label="item.name"
                     :value="item.name">
                  </el-option>
                </el-select>
              </div>
              <div class="questionsTypeMr" v-if="compositonWayShow==1&&!vocationalShow">
                <el-select v-model="questionsStarValue" placeholder="请选择试题难易度">
                  <el-option
                      v-for="item in questionsStarArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                  </el-option>
                </el-select>
              </div>
              <div v-if="compositonWayShow==2" class="topicNumberMr">
                <el-input v-model="topicNumber" placeholder="请输入试题数量"></el-input>
              </div>
              <div>
                <p class="searchType" @click="searchEx()">搜索</p>
              </div>
            </div>
<!--            试题列表-->
            <div class="topicOveflows" v-for="(item,index) in questionList" :key="index" @click="chooseQuestion(item)">
              <div class="topicBorders">

                <div class="topicTitle" :class="topicArr.includes(item.id)?'tab-active':'topicTitle'">
                  <p v-if="!topicArr.includes(item.id)" class="radioQuestion"></p>
                  <p v-if="topicArr.includes(item.id)" class="checkQuestion"></p>
                  <span>{{ index + 1 }}.</span>
                  <span style="width: 100%">{{ item.exercisesTitle }}</span>
                </div>
                <exercisesEnclosure :fileUrl="item.exercisesAccessoryUrl"></exercisesEnclosure>
                <div>
                  <!-- 选择题-->
                  <div v-if="item.exercisesType=='SC' || item.exercisesType=='MC'">
                    <div v-for="(item1, index1) in item.questionOption"
                         :key="index1"
                         class="chooseSpan">
                      <div>
                        {{getLetter(index1)}}：{{ item1 }}
                      </div>
                    </div>
                  </div>
                  <!-- 判断题-->
                  <div v-if="item.exercisesType=='TF'">
                    <el-radio v-model="chooseRadio" disabled label="选中且禁用">正确</el-radio>
                    <el-radio v-model="chooseRadio" label="2" disabled >错误</el-radio>
                  </div>
                  <!-- 简答题-->
                  <div v-if="item.exercisesType=='SAQ'">
                    <el-input
                        type="textarea"
                        disabled
                        :autosize="{ minRows: 4, maxRows: 10 }"
                    ></el-input>
                  </div>
<!--                  填空题-->
                  <div v-if="item.exercisesType=='FB'">
                    <div v-for="i in item.exercisesBody" :key="i">
                      <el-input disabled class="tiankongInput"></el-input>
                    </div>
                  </div>
<!--                  完形填空-->
                  <div v-if="item.exercisesType=='CT'||item.exercisesType=='RC'" class="synthesizeEx">
                    <div v-for="(exItem,exIndex) in synthesizeExercise" :key="exIndex" class="synthesizeMr">
                      <exercisesSC v-if="exItem.exercisesType=='SC' " :exercisesInfo="exItem" :openType="4" :exercisesListIndex="exIndex+1">
                      </exercisesSC>
                      <exercisesMC v-if=" exItem.exercisesType=='MC'" :exercisesInfo="exItem" :openType="4" :exercisesListIndex="exIndex+1">
                      </exercisesMC>
                      <exercisesFB v-if="exItem.exercisesType=='FB'" :exercisesInfo="exItem" :openType="4" :exercisesListIndex="exIndex+1">
                      </exercisesFB>
                      <exercisesSAQ v-if="exItem.exercisesType=='SAQ' " :exercisesInfo="exItem" :openType="4" :exercisesListIndex="exIndex+1">
                      </exercisesSAQ>
                      <exercisesTF v-if="exItem.exercisesType=='TF' " :exercisesInfo="exItem" :openType="4" :exercisesListIndex="exIndex+1">
                      </exercisesTF>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 30px;" v-if="compositonWayShow==1">
              <el-pagination
                  class="text_center"
                  background
                  @current-change="handleworkList"
                  :current-page.sync="workPage"
                  :page-size="worksize"
                  layout="total,  prev, pager, next, jumper"
                  :total="worktotal"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div class="submitAddQuestion" >
          <p @click="addAccomplish">添加完成</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="inputScoreWindow" :show-close="true" width="50%" title="请设置分数">
      <p style="font-size: 16px">共 <span class="questionColor">{{selectQuestionListLength}}</span> 题</p>
      <div class="inputScorePadding">
        <div v-if="exercisesInputScore.exercisesInputScoreSC">
          <p class="SetinputScore">单选题 <span class="questionColor">({{selectQustionNumber.typeSC.length}})</span></p>
          <el-input v-model="templateDetailData.scoreNumberSC"></el-input>
        </div>
        <div v-if="exercisesInputScore.exercisesInputScoreMC">
          <p class="SetinputScore">多选题 <span class="questionColor">({{selectQustionNumber.typeMC.length}})</span></p>
          <el-input v-model="templateDetailData.scoreNumberMC"></el-input>
        </div>
        <div v-if="exercisesInputScore.exercisesInputScoreFB">
          <p class="SetinputScore">填空题 <span class="questionColor">({{selectQustionNumber.typeFB.length}})</span></p>
          <el-input v-model="templateDetailData.scoreNumberFB"></el-input>
        </div>
        <div v-if="exercisesInputScore.exercisesInputScoreTF">
          <p class="SetinputScore">判断题 <span class="questionColor">({{selectQustionNumber.typeTF.length}})</span></p>
          <el-input v-model="templateDetailData.scoreNumberTF"></el-input>
        </div>
        <div v-if="exercisesInputScore.exercisesInputScoreSAQ">
          <p class="SetinputScore">简答题 <span class="questionColor">({{selectQustionNumber.typeSAQ.length}})</span></p>
          <el-input v-model="templateDetailData.scoreNumberSAQ"></el-input>
        </div>
        <div v-if="exercisesInputScore.exercisesInputScoreRC">
          <p class="SetinputScore">阅读理解 <span class="questionColor">({{selectQustionNumber.typeRC.length}})</span></p>
          <el-input v-model="templateDetailData.scoreNumberRC"></el-input>
        </div>
        <div v-if="exercisesInputScore.exercisesInputScoreCT">
          <p class="SetinputScore">完形填空 <span class="questionColor">({{selectQustionNumber.typeCT.length}})</span></p>
          <el-input v-model="templateDetailData.scoreNumberCT"></el-input>
        </div>
        <div class="submitInputScore">
          <el-button type="primary" @click="gopushExamintion()">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="randomQuestionShow" :show-close="true" width="62%" title="快速组卷">
      <el-card>
        <div style="padding: 0 12%">
          <div class="qustionWindowScore">
            <span></span>
            <span>数量</span>
            <span>分值</span>
          </div>
          <div v-if="!vocationalShow">
            <div class="questionFlexRandiom" v-for="(item,index) in arrQuestion" :key="index" style="margin-bottom: 10px;">
              <p>{{item.name}}</p>
              <p class="questionFlexRandiom">
                <el-input v-model="item.number" :placeholder="'最多不能超过'+item.exercisesTotal+'道'"></el-input>
                <span class="questionMr">题</span>
              </p>
              <p class="questionFlexRandiom">
                <el-input v-model="item.score"></el-input>
                <span class="questionMr">分</span>
              </p>
            </div>
          </div>
          <div v-if="vocationalShow">
            <div class="questionFlexRandiom" v-for="(item,index) in vocationalArrQuestion" :key="index" style="margin-bottom: 10px;">
              <p>{{item.name}}</p>
              <p class="questionFlexRandiom">
                <el-input v-model="item.number" :placeholder="'最多不能超过'+item.exercisesTotal+'道'"></el-input>
                <span class="questionMr">题</span>
              </p>
              <p class="questionFlexRandiom">
                <el-input v-model="item.score"></el-input>
                <span class="questionMr">分</span>
              </p>
            </div>
          </div>
          <div class="questionFooting">
            <span>合计</span>
            <span>{{numberTotal}}</span>
            <span>题</span>
            <span>{{scoreCountTotal}}</span>
            <span>分</span>
          </div>
        </div>
      </el-card>
      <div style="" class="addPUsh">
        <el-button @click="addPushRandom" type="primary">添加完成</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  courseList,
  operationTopic,
  createExamination,
  createExaminationDetailAPI,
  selectTemplateDetailList,
  deleteExamitionDetail,
  randomGenerateExamition,
  itemAggByExercisesType,
  majorList,
  subjectList,
  textbooksList,
  textbooksChapterList,
  textbooksChapterExercisesList,
  randomChapterExercisesList,
  randomPaper,
  randomPaperNumber,
  deleteClass
} from "@/api";
import CustomUpload from "@/components/custom-upload";
import exercisesEnclosure from '@/components/exercisesList/exercisesEnclosure'
import exercisesSC from "@/components/exercisesList/exercisesSC";
import exercisesFB from "@/components/exercisesList/exercisesFB";
import exercisesMC from "@/components/exercisesList/exercisesMC";
import exercisesSAQ from "@/components/exercisesList/exercisesSAQ";
import exercisesTF from "@/components/exercisesList/exercisesTF";
  export default {
    components: {
      CustomUpload,
      exercisesEnclosure,
      exercisesFB,
      exercisesMC,
      exercisesSAQ,
      exercisesSC,
      exercisesTF
    },
    data(){
      return{
        numberTotal:0,
        scoreCountTotal:0,
        rapidCollegeEntranceMajorId:'',
        rapidCourseId:[],
        arrQuestion:[
          {id:1,name:'单选题',number:'',score:0,type:'SC',exercisesTotal:'0'},
          {id:2,name:'多选题',number:'',score:0,type:'MC',exercisesTotal:'0'},
          {id:3,name:'简答题',number:'',score:0,type:'SAQ',exercisesTotal:'0'},
          {id:4,name:'填空题',number:'',score:0,type:'FB',exercisesTotal:'0'},
          {id:5,name:'判断题',number:'',score:0,type:'TF',exercisesTotal:'0'},
          {id:6,name:'阅读理解',number:'',score:0,type:'RC',exercisesTotal:'0'},
          {id:7,name:'完形填空',number:'',score:0,type:'CT',exercisesTotal:'0'},
        ],
        vocationalArrQuestion:[
          {id:1,name:'单选题',number:'',score:0,type:'SC',exercisesTotal:'0'},
          {id:2,name:'简答题',number:'',score:0,type:'SAQ',exercisesTotal:'0'},
          {id:3,name:'综合分析题',number:'',score:0,type:'RC',exercisesTotal:'0'},
        ],
        randomQuestionShow:false,
        chapterData:[],
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        vocationalShow:'',
        majorListArr:[],
        questionList:[],
        questionsTypeValue:"",
        questionsStarValue:"",
        questionsTypeArr:[
          {id:1,name:"单项选择题"},
          {id:2,name:"多项选择题"},
          {id:3,name:"判断题"},
          {id:4,name:"填空题"},
          {id:5,name:"简答题"},
          {id:6,name:"综合分析题"},
          {id:7,name:"完形填空"},
        ],
        synthesizeQuestionsTypeArr:[
          {id:1,name:"单项选择题"},
          {id:2,name:"多项选择题"},
          {id:3,name:"判断题"},
          {id:4,name:"填空题"},
          {id:5,name:"简答题"},
          {id:6,name:"综合分析题"},
          {id:7,name:"完形填空"},
        ],
        questionsStarArr:[
          {id:1,name:'1星'},
          {id:2,name:'2星'},
          {id:3,name:'3星'},
          {id:4,name:'4星'},
          {id:5,name:'5星'},
        ],
        addActive:1,
        addExaminationOne:true,
        compositonWayShow:'1',
        ExaminationWindowShow:false,
        addExaminationTwo:false,
        schoolCourseLIstArr:[],
        courseItemList:[],
        courseDetailArr: [],
        chooseRadio: '',
        courseInfoDetail:{},
        chooseQuestionList:[],
        topicArr:[],
        newQuestion:true,
        addExaminationThree:false,
        topicNumber:"",
        worktotal:0,
        workPage:1,
        worksize:10,
        examinationData:{
          name:'',
          platformCourseId:'',
          logo:'',
          keyWords:'',
          papersDescribe:'',
          teacherId:'',
          schoolId:'',
          privateTeacherData:'1',
          chapterArr:[],
        },
        exercisesTypeSize:{
          exercisesSC:0,
          exercisesMC:0,
          exercisesFB:0,
          exercisesTF:0,
          exercisesSAQ:0,
          exercisesRC:0,
          exercisesCT:0
        },
        amendexAmination:'',
        amendexAminationId:'',
        templateDetailData:{
          examPapersId:'',
          scoreNumberSC:0,
          scoreNumberMC:0,
          scoreNumberFB:0,
          scoreNumberTF:0,
          scoreNumberSAQ:0,
          scoreNumberRC:0,
          scoreNumberCT:0,
          exercisesType:'',
          exercisesTitle:'',
          exercisesBody:'',
          exercisesAccessoryUrl:'',
          answerType:'TXT',
          correctAnswer:'',
          analysis:'',
          starNumber:''
        },
        currentPage:1,
        pagesize:200,
        total:0,
        templateDetailId:'',
        deleteQuestionDetailId:'',
        examPapersItemIds:[],
        addNewsQuestionData:false,
        newsChooseQuestionList:[],
        newsAmendQuestionShow:false,
        deleteExaminationDetailMark:false,
        setChooseQuestionList:[],
        setNewsChooseQuestionList:[],
        //在选择习题页面选择的习题集合
        selectQuestionList:[],
        amendexAminationlogo:'',
        amendexAminationName:'',
        watchScore:{
          ScScore:false,
          McScore:false,
          FBScore:false,
          TFScore:false,
          SAQScore:false,
          RCScore:false,
          CTScore:false,
        },
        inputScoreWindow:false,
        exercisesInputScore:{
          exercisesInputScoreSC:false,
          exercisesInputScoreMC:false,
          exercisesInputScoreTF:false,
          exercisesInputScoreFB:false,
          exercisesInputScoreSAQ:false,
          exercisesInputScoreRC:false,
          exercisesInputScoreCT:false,
        },
        scoreTotal:{
          scoreScTotal:0,
          scoreMcTotal:0,
          scoreTFTotal:0,
          scoreFBTotal:0,
          scoreSAQTotal:0,
          scoreRCTotal:0,
          scoreCTTotal:0,
        },
        totalExercisesNumber:0,
        deleteChooseQuestionList:[],
        deleteNewsChooseQuestionList:[],
        updateChooseQuestionList:[],
        updateNewsChooseQuestionList:[],
        oldScoreTotal:{
          scoreScTotal:0,
          scoreMcTotal:0,
          scoreTFTotal:0,
          scoreFBTotal:0,
          scoreSAQTotal:0,
          scoreRCTotal:0,
          scoreCTTotal:0,
        },
        materialArr:[],
        synthesizeExercise:[],
        selectQuestionListLength:'',
        selectQustionNumber:{},
        compositionShow:'1',
        subjectType:''
      }
    },
    methods:{
      changeCas(){
      },
      getLetter(index){
        return String.fromCharCode(65 + index)
      },
      //获取一键组卷题库数量
      getrandomPaperNumber(){
        let arr=[]
        let collegeEntranceSubjectIds=''
        if(typeof this.examinationData.platformCourseId!=='string'){
          this.examinationData.platformCourseId.forEach(item=>{
            arr.push(item[1])
          })
          collegeEntranceSubjectIds=arr.join(',')
        }else{
          collegeEntranceSubjectIds=this.examinationData.platformCourseId
        }
        let query={
          collegeEntranceSubjectId:collegeEntranceSubjectIds,
          exercisesType:2
        }
        if(this.vocationalShow){
          query.exercisesType=1
        }
        randomPaperNumber(query).then(res => {
          if(res.data&&res.data.length){
            res.data.forEach(item=>{
              this.vocationalArrQuestion.forEach((item1,index1)=>{
                if(item.exercisesType==item1.type){
                  this.$set(this.vocationalArrQuestion[index1],"exercisesTotal",item.exercisesTotal);
                }
              })
              this.arrQuestion.forEach((item1,index1)=>{
                if(item.exercisesType==item1.type){
                  this.$set(this.arrQuestion[index1],"exercisesTotal",item.exercisesTotal);
                }
              })
            })
          }

        })
      },
      //一键组卷添加完成
      addPushRandom(){
        let quetion=[]
        if(this.vocationalShow){
          this.vocationalArrQuestion.forEach(item=>{
            let obj={}
            if(item.id==1){
              obj.type='SC';
              obj.number=item.score;
              obj.count=item.number;
            }
            if(item.id==2){
              obj.type='SAQ';
              obj.number=item.score;
              obj.count=item.number;
            }
            if(item.id==3){
              obj.type='RC';
              obj.number=item.score;
              obj.count=item.number;
            }
            quetion.push(obj)
          })
        }else{
          this.arrQuestion.forEach(item=>{
            let obj={}
            if(item.id==1){
              obj.type='SC';
              obj.number=item.score;
              obj.count=item.number;
            }
            if(item.id==2){
              obj.type='MC';
              obj.number=item.score;
              obj.count=item.number;
            }
            if(item.id==3){
              obj.type='SAQ';
              obj.number=item.score;
              obj.count=item.number;
            }
            if(item.id==4){
              obj.type='FB';
              obj.number=item.score;
              obj.count=item.number;
            }
            if(item.id==5){
              obj.type='TF';
              obj.number=item.score;
              obj.count=item.number;
            }
            if(item.id==6){
              obj.type='RC';
              obj.number=item.score;
              obj.count=item.number;
            }
            if(item.id==7){
              obj.type='CT';
              obj.number=item.score;
              obj.count=item.number;
            }
            quetion.push(obj)
          })
        }
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let query={
          detailList:quetion,
          schoolId:userInfo.schoolId,
          teacherId:userInfo.id,
          privateTeacherData:this.examinationData.privateTeacherData,
          name:this.examinationData.name,
          keyWords:this.examinationData.keyWords,
          logo:this.examinationData.logo,
          papersDescribe:this.examinationData.papersDescribe,
          testPaperType:0,
        }
        if(this.vocationalShow){
          query.testPaperType=1;
          query.collegeEntranceMajorId=this.rapidCollegeEntranceMajorId;
          query.collegeEntranceSubjectId=this.rapidCourseId
        }else{
          query.platformCourseId=this.examinationData.platformCourseId
        }
        // console.log(query);return
        randomPaper(query).then(res => {
          if(res.code==0){
            this.$message({
              message: '成功',
              type: 'success'
            });
            this.randomQuestionShow=false
            this.addExaminationThree=true;
            this.addExaminationOne=false;
            this.addExaminationTwo=false;
          }
        })
      },
      //添加完成
      addAccomplish(){
        this.inputScoreWindow=true
      },
      //显示一键组卷弹窗
      changeRandiom(){
        this.getrandomPaperNumber()
        this.randomQuestionShow=true
      },
      //添加新数据
      gopushExamintion(){
        this.ExaminationWindowShow=false;
        this.newQuestion=false;
        this.inputScoreWindow=false
        let copySelectQuestionList = JSON.parse(JSON.stringify(this.selectQuestionList));
        this.selectQuestionList = [];
        // this.setChooseQuestionList = this.setChooseQuestionList.concat(this.unique(copySelectQuestionList));
        copySelectQuestionList.forEach(item=>{
          if(item.exercisesType=='SC'){
            item.scoreNumber=this.templateDetailData.scoreNumberSC;
            item.newScoreNumber = this.templateDetailData.scoreNumberSC;
            item.perScoreNumber = this.templateDetailData.scoreNumberSC;
          }
          if(item.exercisesType=='MC'){
            item.scoreNumber=this.templateDetailData.scoreNumberMC;
            item.newScoreNumber = this.templateDetailData.scoreNumberMC;
            item.perScoreNumber = this.templateDetailData.scoreNumberMC;
          }
          if(item.exercisesType=='FB'){
            item.scoreNumber=this.templateDetailData.scoreNumberFB;
            item.newScoreNumber = this.templateDetailData.scoreNumberFB;
            item.perScoreNumber = this.templateDetailData.scoreNumberFB;
          }
          if(item.exercisesType=='TF'){
            item.scoreNumber=this.templateDetailData.scoreNumberTF;
            item.newScoreNumber = this.templateDetailData.scoreNumberTF;
            item.perScoreNumber = this.templateDetailData.scoreNumberTF;
          }
          if(item.exercisesType=='SAQ'){
            item.scoreNumber=this.templateDetailData.scoreNumberSAQ;
            item.newScoreNumber = this.templateDetailData.scoreNumberSAQ;
            item.perScoreNumber = this.templateDetailData.scoreNumberSAQ;
          }
          if(item.exercisesType=='RC'){
            item.scoreNumber=this.templateDetailData.scoreNumberRC;
            item.newScoreNumber = this.templateDetailData.scoreNumberRC;
            item.perScoreNumber = this.templateDetailData.scoreNumberRC;
          }
          if(item.exercisesType=='CT'){
            item.scoreNumber=this.templateDetailData.scoreNumberCT;
            item.newScoreNumber = this.templateDetailData.scoreNumberCT;
            item.perScoreNumber = this.templateDetailData.scoreNumberCT;
          }
        })

        if(this.templateDetailId){
          //修改新增
          this.newsChooseQuestionList = this.newsChooseQuestionList.concat(copySelectQuestionList);
        }else{
          //新建新增
          this.chooseQuestionList = this.chooseQuestionList.concat(copySelectQuestionList);
        }
        this.setNewsChooseQuestionList.forEach(item=>{
          let arr1 = this.unique(this.chooseQuestionList);
          this.chooseQuestionList=arr1;
          let arr2 = this.unique(this.newsChooseQuestionList);
          this.newsChooseQuestionList=arr2
        })
        this.chooseQuestionList = this.unique(this.chooseQuestionList);
        this.templateDetailData = {
              scoreNumberSC:0,
              scoreNumberMC:0,
              scoreNumberFB:0,
              scoreNumberTF:0,
              scoreNumberSAQ:0,
              scoreNumberRC:0,
              scoreNumberCT:0,
        }
      },
      //选择试题
      chooseQuestion(item){
        this.newsAmendQuestionShow=true;
        let index = this.topicArr.indexOf(item.id);
        if (index !== -1) {
          this.topicArr.splice(index, 1);
          this.selectQuestionList.splice(index,1);
          // this.chooseQuestionList.splice(index,1);
          this.setNewsChooseQuestionList.splice(index,1)
          return;
        }
        this.topicArr.push(item.id);
        let questions=Object.assign({scoreNumber:0,newScoreNumber:0,oldScoreNumber:0,perScoreNumber:0},item);

        this.selectQuestionList.push(questions);
        // this.setChooseQuestionList.push(questions);
        if(this.addNewsQuestionData){
          this.setNewsChooseQuestionList.push(questions)
        }
        this.selectQuestionList = this.unique(this.selectQuestionList);
        this.selectQuestionListLength=this.selectQuestionList.length
        let selectQustionNumber={
          typeSC:[],
          typeMC:[],
          typeSAQ:[],
          typeFB:[],
          typeTF:[],
          typeRC:[],
          typeCT:[],
        }
        this.selectQuestionList.forEach(item=>{
          if(item.exercisesType=='SC'){
            selectQustionNumber.typeSC.push(item)
          }
          if(item.exercisesType=='MC'){
            selectQustionNumber.typeMC.push(item)
          }
          if(item.exercisesType=='SAQ'){
            selectQustionNumber.typeSAQ.push(item)
          }
          if(item.exercisesType=='TF'){
            selectQustionNumber.typeTF.push(item)
          }
          if(item.exercisesType=='FB'){
            selectQustionNumber.typeFB.push(item)
          }
          if(item.exercisesType=='CT'){
            selectQustionNumber.typeCT.push(item)
          }
          if(item.exercisesType=='RC'){
            selectQustionNumber.typeRC.push(item)
          }
        })
        this.selectQustionNumber=selectQustionNumber
      },
      //  获取课程详情树形结构
      getCourseDetailClassify() {
        if(this.examinationData.chapterArr){
          let courseItemList = this.examinationData.chapterArr
          const listToTree = (listArr, pid) => {
            let tree = [];
            listArr.forEach((item, index) => {
              if (item.pid === pid) {
                let child = listToTree(courseItemList, item.id)
                let data = {...item}
                child && child.length && (data['children'] = child)
                tree.push(data)
              }
            })
            return tree;
          }
          this.courseDetailArr = listToTree(courseItemList, '0');
        }

      },
      chooseChilrden(item){
        this.courseInfoDetail=item
        this.getcourseXiti()
      },
      //搜索习题
      searchEx(){
        this.workPage=1
        if(!this.vocationalShow){
          this.getcourseXiti()
        }else{
          this.handleNodeClick(this.chapterData[0])
        }
      },
      //习题列表
      getcourseXiti(){
        let query = {
          platformCourseId:this.courseInfoDetail.platformCourseId,
          platformCourseItemId:this.courseInfoDetail.id,
          page:this.workPage,
          size:this.worksize,
          sourceDiff:''
        }
        if(this.questionsStarValue){
          query['starNumber']=this.questionsStarValue.slice(0,1)
        };
        if(this.questionsTypeValue&&this.questionsTypeValue=='单项选择题'){
          query['exercisesType']='SC'
        }else if(this.questionsTypeValue&&this.questionsTypeValue=='多项选择题'){
          query['exercisesType']='MC'
        }else if(this.questionsTypeValue&&this.questionsTypeValue=='判断题'){
          query['exercisesType']='TF'
        }else if(this.questionsTypeValue&&this.questionsTypeValue=='填空题'){
          query['exercisesType']='FB'
        }else if(this.questionsTypeValue&&this.questionsTypeValue=='简答题'){
          query['exercisesType']='SAQ'
        }else if(this.questionsTypeValue&&this.questionsTypeValue=='综合分析题'){
          query['exercisesType']='RC'
        }else if(this.questionsTypeValue&&this.questionsTypeValue=='完形填空'){
          query['exercisesType']='CT'
        }
        if(this.compositonWayShow==1){
          operationTopic(query).then(res => {
            this.worktotal = JSON.parse(res.data.total);
            res.data.records.forEach((item, index) => {
              if(item.exercisesBody){
                item.questionOption = item.exercisesBody.split('\@')
              }
              if(item.correctAnswer){
                item.correctAnswerArr = item.correctAnswer.split(',')
              }
              if(item.exercisesType=='FB'){
                item.exercisesBody=JSON.parse(item.exercisesBody)
              }
            })
            this.questionList = res.data.records;
          })
        }else{
          query['randomNumber']='10';
          if(this.topicNumber){
            query['randomNumber']=this.topicNumber;
          }
          randomGenerateExamition(query).then(res => {
            res.data.forEach((item, index) => {
              if(item.exercisesBody){
                item.questionOption = item.exercisesBody.split('\@')
              }
              if(item.correctAnswer){
                item.correctAnswerArr = item.correctAnswer.split(',')
              }
              if(item.exercisesType=='FB'){
                item.exercisesBody=JSON.parse(item.exercisesBody)
              }
            })
            this.questionList = res.data;
          })
        }
      },
      //习题分页
      //  习题作业分页
      handleworkList(workPage) {
        this.workPage = workPage;
        if(!this.vocationalShow){
          this.getcourseXiti()
        }else{
          this.handleNodeClick(this.chapterData[0])
        }

      },
      //上一步
      upStep(){
        this.addExaminationOne=true;
        this.addExaminationTwo=false;
        this.addActive--
      },
      //下一步
      preserveNext(){
        if (this.addActive++ > 2) this.addActive = 0;
        if(!this.examinationData.name){
          this.$message({
            message: '请输入名称',
            type: 'warning'
          });return
        }
        if(!this.examinationData.platformCourseId){
          this.$message({
            message: '请选择课程',
            type: 'warning'
          });return
        }
        if(!this.vocationalShow){
          this.changeCourse()
        }else{
          this.getVocationalChapter()
        }
        this.addExaminationOne=false;
        this.addExaminationTwo=true;
        this.getCourseDetailClassify();
        this.getTemplateDetailList();
      },
    //  创建试卷模板详情
      createAmintionDetail(){
        let dataArr=[];
        if(this.templateDetailId){
          if(this.newsChooseQuestionList&&this.newsChooseQuestionList.length){
            this.newsChooseQuestionList.forEach(item=>{
              let data={
                examPapersId:this.amendexAminationId,
                scoreNumber:item.scoreNumber,
                exercisesId:item.id,
                exercisesType:item.exercisesType,
                exercisesTitle:item.exercisesTitle,
                exercisesBody:item.exercisesBody,
                exercisesAccessoryUrl:item.exercisesAccessoryUrl,
                answerType:'TXT',
                correctAnswer:item.correctAnswer,
                analysis:item.analysis,
                starNumber:item.starNumber
              }
              dataArr.push(data);
            })
          }
          this.chooseQuestionList.forEach(item=>{
            let data1={
              examPapersId:this.amendexAminationId,
              exercisesId:item.id,
              exercisesType:item.exercisesType,
              exercisesTitle:item.exercisesTitle,
              exercisesBody:item.exercisesBody,
              exercisesAccessoryUrl:item.exercisesAccessoryUrl,
              answerType:'TXT',
              correctAnswer:item.correctAnswer,
              analysis:item.analysis,
              starNumber:item.starNumber,
              scoreNumber:item.scoreNumber
            }
            if(this.templateDetailId&&!this.deleteExaminationDetailMark){
              data1.id=item.id
            }
            dataArr.push(data1);
          });
        }else{
          //新建
          this.chooseQuestionList.forEach(item=>{
            let data={
              examPapersId:this.amendexAminationId,
              exercisesId:item.id,
              exercisesType:item.exercisesType,
              exercisesTitle:item.exercisesTitle,
              exercisesBody:item.exercisesBody,
              exercisesAccessoryUrl:item.exercisesAccessoryUrl,
              answerType:'TXT',
              correctAnswer:item.correctAnswer,
              analysis:item.analysis,
              starNumber:item.starNumber,
              scoreNumber:item.scoreNumber
            }
            // if(this.templateDetailId&&!this.deleteQuestionDetailId&&!this.deleteExaminationDetailMark){
            //   data.id=item.id
            // }
            dataArr.push(data);
          });
        }
        createExaminationDetailAPI(dataArr).then(res=>{
          if(res.code==0&&!this.templateDetailId){
            this.$message({
              message: "创建成功",
              type: "success",
            });
          }else if(res.code==0&&this.templateDetailId){
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else{
            this.$message.error(res.msg)
          }
        })
      },
    //  最后一步
      lastStep(){
        this.addActive++;
        this.addExaminationOne=false;
        this.addExaminationTwo=false;
        this.addExaminationThree=true;
        this.createAmendExamination()

      },
    //  数组去重
      unique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
      },
    //  修改删除试卷详情模板
      amendexAminationDetial(){
        let examPapersItemIds1=[]
        this.chooseQuestionList.forEach(item=>{
          examPapersItemIds1.push(item.id)
        })
        let examPapersItemIds2=this.examPapersItemIds;
        deleteExamitionDetail(this.deleteExaminationDetailMark?examPapersItemIds1:examPapersItemIds2).then(res=>{
          if(res.code==0&&!this.deleteExaminationDetailMark){
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          }else if(res.code==0&&this.deleteExaminationDetailMark){
            this.$message({
              message: '删除成功',
              type: 'success'
            });

            this.getTemplateDetailList()
          }else if(res.code=='100123'){
            this.$message('还未创建,不可删除试卷详情')
          }else{
            this.$message.error(res.msg)
          }
        })
      },
    //  修改试卷模板
      amendExamination(){
        if(!this.examinationData.name){
          this.$message({
            message: '标题不可为空',
            type: 'warning'
          });return
        }
        if(!this.examinationData.platformCourseId){
          this.$message({
            message: '请选择课程',
            type: 'warning'
          });return
        }

      },
    //  创建或修改试卷模板
      createAmendExamination(){
        // console.log('this.examPapersItemIds',this.examPapersItemIds)
        // console.log('this.newsAmendQuestionShow',this.newsAmendQuestionShow);return;
        if(!this.examinationData.name){
          this.$message({
            message: '标题不可为空',
            type: 'warning'
          });return
        }
        if(!this.examinationData.platformCourseId){
          this.$message({
            message: '请选择课程',
            type: 'warning'
          });return
        }
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
          schoolId:userInfo.schoolId,
          teacherId:userInfo.id,
          privateTeacherData:this.examinationData.privateTeacherData,
          name:this.examinationData.name,
          keyWords:this.examinationData.keyWords,
          logo:this.examinationData.logo,
          papersDescribe:this.examinationData.papersDescribe,
          testPaperType:0,
          subjectType:1
        }
        if(this.subjectType&&this.subjectType==2){
          data.subjectType=2
        }
        if(this.vocationalShow){
          data.testPaperType=1;
          data.otherInfo=this.examinationData.platformCourseId+''
        }else{
          data.platformCourseId=this.examinationData.platformCourseId
        }
        if(this.amendexAminationId){
          data['id']=this.amendexAminationId
        }
        createExamination(data).then(res=>{
          if(res.code==0){
            this.amendexAminationId=res.data.id;
            this.amendexAminationlogo=res.data.logo;
            this.amendexAminationName=res.data.name
            this.createAmintionDetail();
            if(this.examPapersItemIds&&this.examPapersItemIds.length){
              if(this.templateDetailId){
                this.amendexAminationDetial()
              }
            }
            if(this.newsAmendQuestionShow&&this.examPapersItemIds&&this.examPapersItemIds.length){
              if(this.templateDetailId){
                this.amendexAminationDetial()
              }
            }
          }else{
            this.$message.error(res.msg)
          }
        })
      },
    //  添加试题弹窗显示
      addExaminationWindow(){

        this.ExaminationWindowShow=true;
      },
      //获取职教高考教材及章节
      getVocationalChapter(){
        let chapterArr=[];
        let collegeEntranceMajorId=''
        let collegeEntranceMajorIdArr=[];
        let courseId=[]
        this.examinationData.platformCourseId.forEach(item=>{
          let data={
            collegeEntranceMajorId:item[0],
            collegeEntranceSubjectId:item[1]
          };
          textbooksList(data).then(res=>{
            if(res.data.records[0].subjectType){
              this.subjectType=res.data.records[0].subjectType
            }
            res.data.records.forEach(resData=>{
              this.materialArr.push(resData)
              chapterArr.push(resData)
              collegeEntranceMajorIdArr.push(resData.collegeEntranceMajorId)
              courseId.push(resData.collegeEntranceSubjectId)
            })
            collegeEntranceMajorId= this.unique(collegeEntranceMajorIdArr);
            this.rapidCollegeEntranceMajorId=JSON.parse(collegeEntranceMajorId)
            this.rapidCourseId=courseId
            chapterArr.forEach(item1=>{
              let data={
                collegeEntranceTextbooksId:item1.id
              };
              textbooksChapterList(data).then(res=>{
                let courseItemList = res.data.records
                const listToTree = (listArr, pid) => {
                  let tree = [];
                  listArr.forEach((item3, index) => {
                    if (item3.pid === pid) {
                      let child = listToTree(courseItemList, item3.id)
                      let data = {...item3}
                      child && child.length && (data['children'] = child)
                      tree.push(data)
                    }
                  })
                  return tree;
                }
                item1['children']= listToTree(courseItemList, '0');
              });
            })
          });
        })
        this.chapterData=chapterArr;
      },
      //选择职教高考素材下的章节
      handleNodeClick(data) {
        let query={
          collegeEntranceMajorId:data.collegeEntranceMajorId,
          collegeEntranceSubjectId:data.collegeEntranceSubjectId,
          collegeEntranceTextbooksId:data.id,
        };
        query.page=this.workPage
        query.size=this.worksize
        this.materialArr.forEach(item=>{
          if(!data.collegeEntranceMajorId&&item.id==data.collegeEntranceTextbooksId){
            query.collegeEntranceTextbooksChapterId=data.id;
            query.collegeEntranceTextbooksId=data.collegeEntranceTextbooksId
            query.collegeEntranceMajorId=item.collegeEntranceMajorId
            query.collegeEntranceSubjectId=item.collegeEntranceSubjectId
          }
        })
        if(this.questionsStarValue){
          query['starNumber']=this.questionsStarValue.slice(0,1)
        };
        if(this.questionsTypeValue&&this.questionsTypeValue=='单项选择题'){
          query['exercisesType']='SC'
        }else if(this.questionsTypeValue&&this.questionsTypeValue=='多项选择题'){
          query['exercisesType']='MC'
        }else if(this.questionsTypeValue&&this.questionsTypeValue=='判断题'){
          query['exercisesType']='TF'
        }else if(this.questionsTypeValue&&this.questionsTypeValue=='填空题'){
          query['exercisesType']='FB'
        }else if(this.questionsTypeValue&&this.questionsTypeValue=='简答题'){
          query['exercisesType']='SAQ'
        }else if(this.questionsTypeValue){
          if(this.questionsTypeValue=='阅读理解'||this.questionsTypeValue=='综合分析题'){
            query['exercisesType']='RC'
          }
        }else if(this.questionsTypeValue&&this.questionsTypeValue=='完形填空'){
          query['exercisesType']='CT'
        }
        if(this.compositonWayShow==1){

          textbooksChapterExercisesList(query).then(res=>{
            this.worktotal = JSON.parse(res.data.total);
            res.data.records.forEach((item, index) => {
              if(item.exercisesType=='SC'||item.exercisesType=='MC'){
                item.questionOption = item.exercisesBody.split('\@')
              }
              if(item.exercisesType=='FB'){
                item.exercisesBody=JSON.parse(item.exercisesBody)
              }
              if(item.exercisesType=='CT'||item.exercisesType=='RC'){
                this.synthesizeExercise=JSON.parse(item.exercisesBody)
                this.synthesizeExercise.forEach(exItem=>{
                  if(exItem.exercisesType=='SC'||exItem.exercisesType=='MC'){
                    exItem.questionOption = exItem.exercisesBody.split('\@')
                  }
                  if(exItem.exercisesType=='FB'){
                    exItem.exercisesBody=JSON.parse(exItem.exercisesBody)
                  }
                })
              }
            })
            this.questionList = res.data.records;
          })
        }else{
          if(this.topicNumber){
            query['randomNumber']=this.topicNumber;
          }
          randomChapterExercisesList(query).then(res=>{
            // this.worktotal = JSON.parse(res.data.total);
            res.data.forEach((item, index) => {
              if(item.exercisesType=='SC'||item.exercisesType=='MC'){
                item.questionOption = item.exercisesBody.split('\@')
              }
              if(item.exercisesType=='FB'){
                item.exercisesBody=JSON.parse(item.exercisesBody)
              }
              if(item.exercisesType=='CT'||item.exercisesType=='RC'){
                this.synthesizeExercise=JSON.parse(item.exercisesBody)
                this.synthesizeExercise.forEach(exItem=>{
                  if(exItem.exercisesType=='SC'||exItem.exercisesType=='MC'){
                    exItem.questionOption = exItem.exercisesBody.split('\@')
                  }
                  if(exItem.exercisesType=='FB'){
                    exItem.exercisesBody=JSON.parse(exItem.exercisesBody)
                  }
                })
              }
            })
            this.questionList = res.data;
          })
        }

      },
      //修改新增试题
      addNewsQuestion(){
        this.ExaminationWindowShow=true
        this.addNewsQuestionData=true
      },
      //  获取课程列表
      getCourseAdministrationList(){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
          page:1,
          size:200
        }
        data['schoolId']=userInfo.schoolId
        courseList(data).then(res=>{
          this.schoolCourseLIstArr=res.data.records;
        })
      },
    //  选择课程
      changeCourse(){
        let roleForm = this.schoolCourseLIstArr[
            this.schoolCourseLIstArr.findIndex((role) => role.id === this.examinationData.platformCourseId
            )
        ];
        this.examinationData.chapterArr=roleForm.courseItemList;
      },
    //  获取模板详情列表
      getTemplateDetailList(){
        let data={
          examPapersId:this.amendexAminationId,
          page:this.currentPage,
          size:this.pagesize,
        }
        if(this.amendexAminationId!=null&&this.amendexAminationId!=""&&typeof(this.amendexAminationId)!="undefined"){
            selectTemplateDetailList(data).then(res=>{
              res.data.records.forEach((item, index) => {
                this.templateDetailId=item.id
                if(item.exercisesBody){
                  item.questionOption = item.exercisesBody.split('\@')
                }
                item.newScoreNumber = parseFloat(item.scoreNumber);
                item.oldScoreNumber = 0;
                item.perScoreNumber = parseFloat(item.scoreNumber);
                if(item.correctAnswer){
                  item.correctAnswerArr = item.correctAnswer.split(',')
                }
                if(item.exercisesType=='FB'){
                  item.exercisesBody=JSON.parse(item.exercisesBody);
                  if(item.scoreNumber!==undefined){
                    this.templateDetailData.scoreNumberFB=item.scoreNumber;
                  }
                  // FBTotal+=parseFloat(item.scoreNumber)
                  // this.scoreTotal.scoreFBTotal=FBTotal
                }
                if(item.exercisesType==='SC'){
                  if(item.scoreNumber!==undefined){
                    this.templateDetailData.scoreNumberSC= item.scoreNumber
                  }
                  // scTotal+=parseFloat(item.scoreNumber)
                  // this.scoreTotal.scoreScTotal=scTotal
                }
                if(item.exercisesType=='MC'){
                  if(item.scoreNumber!==undefined){
                    this.templateDetailData.scoreNumberMC=item.scoreNumber
                  };
                  // MCTotal+=parseFloat(item.scoreNumber)
                  // this.scoreTotal.scoreMcTotal=MCTotal
                }
                if(item.exercisesType=='TF'){
                  if(item.scoreNumber!==undefined){
                    this.templateDetailData.scoreNumberTF=item.scoreNumber
                  };
                  // TFTotal+=parseFloat(item.scoreNumber)
                  // this.scoreTotal.scoreTFTotal=TFTotal
                }
                if(item.exercisesType=='SAQ'){
                  if(item.scoreNumber!==undefined){
                    this.templateDetailData.scoreNumberSAQ=item.scoreNumber
                  };
                }
                if(item.exercisesType=='RC'){
                  if(item.scoreNumber!==undefined){
                    this.templateDetailData.scoreNumberRC=item.scoreNumber
                  };
                }
                if(item.exercisesType=='CT'){
                  if(item.scoreNumber!==undefined){
                    this.templateDetailData.scoreNumberCT=item.scoreNumber
                  };
                }
              })
              this.chooseQuestionList=res.data.records;
              this.total= JSON.parse(res.data.total)
              this.newQuestion=false;
              this.getitemAggByExercisesType()
            })
        }

      },
    //  获取试题列表分数
      getitemAggByExercisesType(){
        let data={
          examPapersId:this.amendexAminationId
        };
        itemAggByExercisesType(data).then(res=>{
          // this.getExScoreTotal.scAcoreTotal=res.data.sCExercisesList;
          // this.getExScoreTotal.mcAcoreTotal=res.data.mCExercisesList
          // this.getExScoreTotal.fbAcoreTotal=res.data.fBExercisesList
          // this.getExScoreTotal.tfAcoreTotal=res.data.tFExercisesList
          // this.getExScoreTotal.saqAcoreTotal=res.data.sAQExercisesList;
          this.totalExercisesNumber=parseInt(res.data.totalExercisesNumber)

          this.exercisesTypeSize.exercisesFB = res.data.exercisestypeMapList.FB?res.data.exercisestypeMapList.FB.length:0;
          if(this.exercisesTypeSize.exercisesFB==null||typeof(this.exercisesTypeSize.exercisesFB)=="undefined"){
            this.exercisesTypeSize.exercisesFB = 0;
          }
          this.exercisesTypeSize.exercisesPerFB = this.exercisesTypeSize.exercisesFB;

          this.exercisesTypeSize.exercisesSC = res.data.exercisestypeMapList.SC?res.data.exercisestypeMapList.SC.length:0;
          if(this.exercisesTypeSize.exercisesSC==null||typeof(this.exercisesTypeSize.exercisesSC)=="undefined"){
            this.exercisesTypeSize.exercisesSC = 0;
          }
          this.exercisesTypeSize.exercisesPerSC = this.exercisesTypeSize.exercisesSC;

          this.exercisesTypeSize.exercisesMC = res.data.exercisestypeMapList.MC?res.data.exercisestypeMapList.MC.length:0;
          if(this.exercisesTypeSize.exercisesMC==null||typeof(this.exercisesTypeSize.exercisesMC)=="undefined"){
            this.exercisesTypeSize.exercisesMC = 0;
          }
          this.exercisesTypeSize.exercisesPerMC = this.exercisesTypeSize.exercisesMC

          this.exercisesTypeSize.exercisesTF = res.data.exercisestypeMapList.TF?res.data.exercisestypeMapList.TF.length:0;
          if(this.exercisesTypeSize.exercisesTF==null||typeof(this.exercisesTypeSize.exercisesTF)=="undefined"){
            this.exercisesTypeSize.exercisesTF = 0;
          }
          this.exercisesTypeSize.exercisesPerTF = this.exercisesTypeSize.exercisesTF

          this.exercisesTypeSize.exercisesSAQ = res.data.exercisestypeMapList.SAQ?res.data.exercisestypeMapList.SAQ.length:0;
          if(this.exercisesTypeSize.exercisesSAQ==null||typeof(this.exercisesTypeSize.exercisesSAQ)=="undefined"){
            this.exercisesTypeSize.exercisesSAQ = 0;
          }
          this.exercisesTypeSize.exercisesPerSAQ= this.exercisesTypeSize.exercisesSAQ
          this.exercisesTypeSize.exercisesRC = res.data.exercisestypeMapList.RC?res.data.exercisestypeMapList.RC.length:0;
          if(this.exercisesTypeSize.exercisesRC==null||typeof(this.exercisesTypeSize.exercisesRC)=="undefined"){
            this.exercisesTypeSize.exercisesRC = 0;
          }
          this.exercisesTypeSize.exercisesPerRC= this.exercisesTypeSize.exercisesRC
          this.exercisesTypeSize.exercisesCT= res.data.exercisestypeMapList.CT?res.data.exercisestypeMapList.CT.length:0;
          if(this.exercisesTypeSize.exercisesCT==null||typeof(this.exercisesTypeSize.exercisesCT)=="undefined"){
            this.exercisesTypeSize.exercisesCT = 0;
          }
          this.exercisesTypeSize.exercisesPerCT= this.exercisesTypeSize.exercisesCT
          let fbTotal=0;
          let tfTotal=0;
          let scTotal=0;
          let mcTotal=0;
          let saqTotal=0;
          let rcTotal=0;
          let ctTotal=0;
          res.data.exercisestypeMapList.FB.forEach(item=>{
            if(item.exercisesType=='FB'){
              fbTotal+=parseFloat(item.scoreNumber);
              this.scoreTotal.scoreFBTotal=fbTotal
            }
          })
          res.data.exercisestypeMapList.MC.forEach(item=>{
            mcTotal+=parseFloat(item.scoreNumber);
            this.scoreTotal.scoreMcTotal=mcTotal
          });
          res.data.exercisestypeMapList.SAQ.forEach(item=>{
            saqTotal+=parseFloat(item.scoreNumber);
            this.scoreTotal.scoreSAQTotal=saqTotal
          })
          res.data.exercisestypeMapList.RC.forEach(item=>{
            rcTotal+=parseFloat(item.scoreNumber);
            this.scoreTotal.scoreRCTotal=rcTotal
          })
          res.data.exercisestypeMapList.CT.forEach(item=>{
            ctTotal+=parseFloat(item.scoreNumber);
            this.scoreTotal.scoreCTTotal=ctTotal
          })
          res.data.exercisestypeMapList.SC.forEach(item=>{
            scTotal+=parseFloat(item.scoreNumber);
            this.scoreTotal.scoreScTotal=scTotal
          });
          res.data.exercisestypeMapList.TF.forEach(item=>{
            tfTotal+=parseFloat(item.scoreNumber);
            this.scoreTotal.scoreTFTotal=tfTotal
          })
          if(this.deleteExaminationDetailMark){
            this.oldScoreTotal={
              scoreScTotal:0,
              scoreMcTotal:0,
              scoreTFTotal:0,
              scoreFBTotal:0,
              scoreSAQTotal:0,
              scoreRCTotal:0,
              scoreCTTotal:0,
            }
          }else{
            this.oldScoreTotal = Object.assign({},this.scoreTotal)
          }


        })
      },
    //  删除选中的试题 (chooseQuestionList)
      deleteChooseQuestion(index,item){
        //新建
        if(!this.templateDetailId){
          this.topicArr.splice(index, 1);
          // this.selectQuestionList.splice(index,1);
          this.chooseQuestionList.splice(index,1);
          this.deleteQuestionDetailId=item.id;
        }else{
        //  修改删除
          this.topicArr.splice(index, 1);
          this.chooseQuestionList.splice(index,1);
          this.deleteQuestionDetailId=item.id;
          //保存将删除的习题id
          this.examPapersItemIds.push(item.id)
        }

        if(item.exercisesType=='FB'){
          this.exercisesTypeSize.exercisesPerFB--;
        }
        if(item.exercisesType=='SC'){
          this.exercisesTypeSize.exercisesPerSC--;
        }
        if(item.exercisesType=='MC'){
          this.exercisesTypeSize.exercisesPerMC--;
        }
        if(item.exercisesType=='TF'){
          this.exercisesTypeSize.exercisesPerTF--;
        }
        if(item.exercisesType=='RC'){
          this.exercisesTypeSize.exercisesPerRC--;
        }
        if(item.exercisesType=='CT'){
          this.exercisesTypeSize.exercisesPerCT--;
        }
        if(item.exercisesType=='SAQ'){
          this.exercisesTypeSize.exercisesPerSAQ--;
        }

        if(this.templateDetailId){
          let isPush = true;
          this.deleteChooseQuestionList.forEach(pushItem=>{
            if(pushItem.id==item.id){
              isPush = false;
              pushItem.newScoreNumber = item.newScoreNumber;
              pushItem.oldScoreNumber = item.oldScoreNumber;
              pushItem.scoreNumber = item.scoreNumber;
            }
          })
          if(isPush){
            this.deleteChooseQuestionList.push(item);
          }
        }
      },
      //删除修改新增的试题
      deletAmendNewChooseQuestion(index,item){
        this.topicArr.splice(index, 1);
        this.newsChooseQuestionList.splice(index,1);
        this.deleteNewsChooseQuestionList.push(item);
      },
      // 分页
      handleCurrentChange: function (currentPage) {
        this.currentPage = currentPage;
        this.getTemplateDetailList();
      },
    //  删除试卷详情
      deleteExaminationDetail(){
        this.$confirm("确认清空试题?清空后无法修复", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          this.deleteExaminationDetailMark=true;
          this.amendexAminationDetial();
          this.newsChooseQuestionList=[];
          this.exercisesTypeSize={};

        })
        .catch(() => {});
      },
    //  去试卷列表
      goExamination(){
        this.$router.push({path:"/classCenter"});
        sessionStorage.setItem('newAddExaminationData',"9-3")
      },
    //  去发布试卷
      gopublishExamintion(){
        let query={
          examPapersId:this.amendexAminationId,
          logo:this.amendexAminationlogo,
          name:this.amendexAminationName,
          organizationExam:true,
          examPapersTitle:this.examinationData.name
        }
        sessionStorage.setItem('publishExaminationData',JSON.stringify(query) )
        sessionStorage.setItem('newAddExaminationData',"9-3")
        this.$router.push({path:'/classCenter'})

      },
    //  修改分数重新计算总分
      changeScore(index,item){
        if(item.newScoreNumber==null||item.newScoreNumber==""||typeof(item.newScoreNumber)=="undefined"){
          item.newScoreNumber = 0
        }
        item.oldScoreNumber = parseFloat(item.scoreNumber);
        item.scoreNumber = parseFloat(item.newScoreNumber);
        if(this.templateDetailId){
          let isPush = true;
          this.updateChooseQuestionList.forEach(pushItem=>{
            if(pushItem.id==item.id){
              isPush = false;
              pushItem.newScoreNumber = parseFloat(item.newScoreNumber);
              pushItem.oldScoreNumber = parseFloat(item.oldScoreNumber);
              pushItem.scoreNumber = parseFloat(item.scoreNumber);
            }
          })
          if(isPush){
            this.updateChooseQuestionList.push(item);
          }
        }
      },
    //  修改新增分数重新计算
      changeNewScore(index,item){
        if(item.newScoreNumber==null||item.newScoreNumber==""||typeof(item.newScoreNumber)=="undefined"){
          item.newScoreNumber = 0;
        }
        item.oldScoreNumber = parseFloat(item.scoreNumber);
        item.scoreNumber = parseFloat(item.newScoreNumber);
        let isPush = true;
        this.updateChooseQuestionList.forEach(pushItem=>{
          if(pushItem.id==item.id){
            isPush = false;
            pushItem.newScoreNumber = item.newScoreNumber;
            pushItem.oldScoreNumber = item.oldScoreNumber;
            pushItem.scoreNumber = item.scoreNumber;
          }
        })
        if(isPush){
          this.updateNewsChooseQuestionList.push(item);
        }
      },
      // 专业列表
      getMajorList(){
        let data={};
        majorList().then(res=>{
          let children=[];
          // let textObj={};
          let arr=res.data.records;
          arr.forEach(item=>{
            item.label=item.name;
            item.value=item.id;
            item.disabled=true
            item.children=[]
            subjectList({collegeEntranceMajorId:item.id}).then(res=>{
              res.data.records.forEach(item1=>{
                if(item.id==item1.collegeEntranceMajorId){
                  item.children.push(item1);
                }
              })
              item.children.forEach(item2=>{
                item2.label=item2.name;
                item2.value=item2.id;
              })
            });
          })
          this.majorListArr=arr;
        })
      },

    },
    mounted() {
      this.$other.$emit('setIndexNav',1);
      this.getCourseAdministrationList();
      if(this.$route.query.vocational){
        this.getMajorList();
      }
      if(this.$route.query.amendexAmination){
        let amendexAmination=JSON.parse(this.$route.query.amendexAmination)
        if(amendexAmination.otherInfo) {
          let arr = []
          let platformCourseId = amendexAmination.otherInfo.split(',')
          arr.push(platformCourseId)
          amendexAmination.platformCourseId = JSON.stringify(arr)
        }
        this.amendexAminationId = amendexAmination.id
        this.examinationData = amendexAmination;
        if(this.$route.query.vocational){
          let newPlatformCourseId = JSON.parse(this.examinationData.platformCourseId)
          let newPlatformCourseIdArr=newPlatformCourseId[0]
          let arr2 = newPlatformCourseIdArr.map((item, index)=>{
            if(index % 2){
              return [newPlatformCourseIdArr[index-1], item]
            }else{
              return undefined
            }
          }).filter((item)=>{
            return item !== undefined
          })
          this.examinationData.platformCourseId=arr2
        }else{
          this.examinationData.platformCourseId = this.examinationData.platformCourseId;
        }
      };
      this.vocationalShow=this.$route.query.vocational;
    },
    watch:{
      // 'templateDetailData.scoreNumberSC'(item) {
      //   this.watchScore.ScScore=true
      // },
      // 'templateDetailData.scoreNumberMC'(item) {

      //   this.watchScore.McScore=true
      // },
      // 'templateDetailData.scoreNumberFB'(item) {
      //   this.watchScore.FBScore=true
      // },
      // 'templateDetailData.scoreNumberTF'(item) {
      //   this.watchScore.TFScore=true
      // },
      // 'templateDetailData.scoreNumberSAQ'(item) {
      //   this.watchScore.SAQScore=true
      // },
      // 'exercisesType.exercisesSC'(){
      //   if(!this.exercisesType.exercisesSC.length){
      //     this.scoreTotal.scoreScTotal=0
      //   }
      // },
      // 'exercisesType.exercisesMC'(){
      //   if(!this.exercisesType.exercisesMC.length){
      //     this.scoreTotal.scoreMcTotal=0
      //   }
      // },
      // 'exercisesType.exercisesTF'(){
      //   if(!this.exercisesType.exercisesTF.length){
      //     this.scoreTotal.scoreTFTotal=0
      //   }
      // },
      // 'exercisesType.exercisesFB'(){
      //   if(!this.exercisesType.exercisesFB.length){
      //     this.scoreTotal.scoreFBTotal=0
      //   }
      // },
      // 'exercisesType.exercisesSAQ'(){
      //   if(!this.exercisesType.exercisesSAQ.length){
      //     this.scoreTotal.scoreSAQTotal=0
      //   }
      // },
      vocationalArrQuestion:{
        handler: function () {
          let numberTotal=0;
          let scoreTotal=0;
          this.vocationalArrQuestion.forEach(item=>{
            numberTotal+=Number(item.number)
            scoreTotal+=Number(item.score*item.number)
          })
          this.numberTotal=numberTotal
          this.scoreCountTotal=scoreTotal
        },
        deep: true

      },
      arrQuestion:{
        handler: function () {
          let numberTotal=0;
          let scoreTotal=0;
          this.arrQuestion.forEach(item=>{
            numberTotal+=Number(item.number)
            scoreTotal+=Number(item.score*item.number)
          })
          this.numberTotal=numberTotal
          this.scoreCountTotal=scoreTotal
        },
        deep: true
      },
      selectQuestionList(val,oldVal){
        if(val.length==0){
          this.exercisesInputScore.exercisesInputScoreSC=false;
          this.exercisesInputScore.exercisesInputScoreMC=false;
          this.exercisesInputScore.exercisesInputScoreTF=false;
          this.exercisesInputScore.exercisesInputScoreFB=false;
          this.exercisesInputScore.exercisesInputScoreSAQ=false;
          this.exercisesInputScore.exercisesInputScoreRC=false;
          this.exercisesInputScore.exercisesInputScoreCT=false;
          return;
        }
        let typeStr = "";
        let typeAry = [];
        val.forEach(item=>{
          let exercisesType = item.exercisesType;
          switch (exercisesType) {
            case "SC":
              typeStr+="SC,"
              break;
            case "MC":
              typeStr+="MC,"
                break
            case "TF":
              typeStr+="TF,"
              break;
            case "FB":
              typeStr+="FB,"
              break
            case "SAQ":
              typeStr+="SAQ,"
              break
            case "RC":
              typeStr+="RC,"
              break
            case "CT":
              typeStr+="CT,"
              break
            default:
              break;
          }
        })
        typeStr.split(",").forEach(typeStrItem=>{
          if(typeAry.indexOf(typeStrItem)<0){
            typeAry.push(typeStrItem)
          }
        })
        typeAry.forEach(itemType=>{
          switch (itemType) {
            case "SC":
              this.exercisesInputScore.exercisesInputScoreSC=true;
              break;
            case "MC":
              this.exercisesInputScore.exercisesInputScoreMC=true;
              break
            case "TF":
              this.exercisesInputScore.exercisesInputScoreTF=true;
              break;
            case "FB":
              this.exercisesInputScore.exercisesInputScoreFB=true;
              break
            case "SAQ":
              this.exercisesInputScore.exercisesInputScoreSAQ=true;
              break
            case 'RC':
              this.exercisesInputScore.exercisesInputScoreRC=true;
            case 'CT':
              this.exercisesInputScore.exercisesInputScoreCT=true
            default:
              break;
          }
        })
      },
      newsChooseQuestionList(){


      }
    },
    computed:{
      scoreCom:function(){
        //如果这是修改试卷模板chooseQuestionLisplatformNews/listt数组不参与计算
        //如果是新增,只计算chooseQuestionList
        //如果是修改,只需计算:updateChooseQuestionList,newsChooseQuestionList,deleteChooseQuestionList
        //不管修改试卷模板还是新建试卷模板,用户会对chooseQuestionList或newsChooseQuestionList数组中的进行的操作可能性
        //在对chooseQuestionList或newsChooseQuestionList数组进行操作时,根据操作类型区分,放入不同的数组中或对数组进行截取
        /**
         deleteChooseQuestionList:[],
         deleteNewsChooseQuestionList:[],
         updateChooseQuestionList:[],
         updateNewsChooseQuestionList:[],


                 exercisesType:{
          exercisesSC:[],
          exercisesMC:[],
          exercisesFB:[],
          exercisesTF:[],
          exercisesSAQ:[]
        },
         */

        let scTotal=this.oldScoreTotal.scoreScTotal;
        let FBTotal=this.oldScoreTotal.scoreFBTotal;
        let TFTotal=this.oldScoreTotal.scoreTFTotal;
        let MCTotal=this.oldScoreTotal.scoreMcTotal;
        let SAQTotal=this.oldScoreTotal.scoreSAQTotal;
        let RCTotal=this.oldScoreTotal.scoreRCTotal;
        let CTTotal=this.oldScoreTotal.scoreCTTotal;
        let newScTotal=0;
        let newFBTotal=0;
        let newTFTotal=0;
        let newMCTotal=0;
        let newSAQTotal=0;
        let newRCTotal=0;
        let newCTTotal=0;
        let appendExercisesFBSize = 0;
        let appendExercisesSCSize = 0;
        let appendExercisesMCSize = 0;
        let appendExercisesTFSize = 0;
        let appendExercisesSAQSize = 0;
        let appendExercisesRCSize = 0;
        let appendExercisesCTSize = 0;
        //新增
        if(!this.templateDetailId){
          this.exercisesTypeSize.exercisesFB = 0;
          this.exercisesTypeSize.exercisesSC = 0;
          this.exercisesTypeSize.exercisesMC = 0;
          this.exercisesTypeSize.exercisesTF = 0;
          this.exercisesTypeSize.exercisesSAQ = 0;
          this.exercisesTypeSize.exercisesRC = 0;
          this.exercisesTypeSize.exercisesCT = 0;
          //该数组为新增状态下的新增习题列表
          this.chooseQuestionList.forEach(exitem=>{
            if(exitem.exercisesType=='FB'){
              newFBTotal+=parseFloat(exitem.scoreNumber)
              this.exercisesTypeSize.exercisesFB++;
            }
            if(exitem.exercisesType==='SC'){
              newScTotal+=parseFloat(exitem.scoreNumber)
              this.exercisesTypeSize.exercisesSC++;
            }
            if(exitem.exercisesType=='MC'){
              newMCTotal+=parseFloat(exitem.scoreNumber)
              this.exercisesTypeSize.exercisesMC++;
            }
            if(exitem.exercisesType=='TF'){
              newTFTotal+=parseFloat(exitem.scoreNumber)
              this.exercisesTypeSize.exercisesTF++;
            }
            if(exitem.exercisesType=='SAQ'){
              newSAQTotal+=parseFloat(exitem.scoreNumber)
              this.exercisesTypeSize.exercisesSAQ++;
            }
            if(exitem.exercisesType=='RC'){
              newRCTotal+=parseFloat(exitem.scoreNumber)
              this.exercisesTypeSize.exercisesRC++;
            }
            if(exitem.exercisesType=='CT'){
              newCTTotal+=parseFloat(exitem.scoreNumber)
              this.exercisesTypeSize.exercisesCT++;
            }
          });
        }else{
          //修改
          //该数组为修改状态下的新增习题列表
          this.newsChooseQuestionList.forEach(exitem=>{
            if(exitem.exercisesType=='FB'){
              newFBTotal+=parseFloat(exitem.scoreNumber);
              appendExercisesFBSize++;
            }
            if(exitem.exercisesType==='SC'){
              newScTotal+=parseFloat(exitem.scoreNumber);
              appendExercisesSCSize++;
            }
            if(exitem.exercisesType=='MC'){
              newMCTotal+=parseFloat(exitem.scoreNumber);
              appendExercisesMCSize++;
            }
            if(exitem.exercisesType=='TF'){
              newTFTotal+=parseFloat(exitem.scoreNumber);
              appendExercisesTFSize++;
            }
            if(exitem.exercisesType=='SAQ'){
              newSAQTotal+=parseFloat(exitem.scoreNumber);
              appendExercisesSAQSize++;
            }
            if(exitem.exercisesType=='RC'){
              newRCTotal+=parseFloat(exitem.scoreNumber);
              appendExercisesRCSize++;
            }
            if(exitem.exercisesType=='CT'){
              newCTTotal+=parseFloat(exitem.scoreNumber);
              appendExercisesCTSize++;
            }
          });
        }

        //处理删除的老题目
        if(this.templateDetailId&&this.deleteChooseQuestionList.length>0){
          this.deleteChooseQuestionList.forEach(exitem=>{
              let nowScoreNum = exitem.scoreNumber;
              // let oldScoreNum = item.oldScoreNumber;
              let perScoreNumber = exitem.perScoreNumber;
              // let itemType = item.exercisesType;
              // let comScore = oldScoreNum - nowScoreNum;
              if(exitem.exercisesType=='FB'){
                FBTotal-=parseFloat(nowScoreNum)
              }
              if(exitem.exercisesType=='SC'){
                scTotal-=parseFloat(nowScoreNum)
              }
              if(exitem.exercisesType=='MC'){
                MCTotal-=parseFloat(nowScoreNum)
              }
              if(exitem.exercisesType=='TF'){
                TFTotal-=parseFloat(nowScoreNum)
              }
              if(exitem.exercisesType=='SAQ'){
                SAQTotal-=parseFloat(nowScoreNum)
              }
              if(exitem.exercisesType=='RC'){
                RCTotal-=parseFloat(nowScoreNum)
              }
              if(exitem.exercisesType=='CT'){
                CTTotal-=parseFloat(nowScoreNum)
              }
          });
        }

        //修改老题分数
        if(this.templateDetailId&&this.updateChooseQuestionList.length>0){
          this.updateChooseQuestionList.forEach(item=>{
              let nowScoreNum = item.scoreNumber;
              let oldScoreNum = item.oldScoreNumber;
              let perScoreNumber = item.perScoreNumber;
              let itemType = item.exercisesType;
              let comScore = oldScoreNum - nowScoreNum;
              if(itemType=='FB'){
                FBTotal = FBTotal- perScoreNumber + parseFloat(Math.abs(nowScoreNum))
              }
              if(itemType=='SC'){
                scTotal= scTotal - perScoreNumber +  parseFloat(Math.abs(nowScoreNum))
              }
              if(itemType=='MC'){
                MCTotal= MCTotal - perScoreNumber + parseFloat(Math.abs(nowScoreNum))
              }
              if(itemType=='TF'){
                TFTotal= TFTotal - perScoreNumber + parseFloat(Math.abs(nowScoreNum))
              }
              if(itemType=='SAQ'){
                SAQTotal= SAQTotal - perScoreNumber + parseFloat(Math.abs(nowScoreNum))
              }
              if(itemType=='RC'){
                RCTotal= RCTotal - perScoreNumber + parseFloat(Math.abs(nowScoreNum))
              }
              if(itemType=='CT'){
                CTTotal= CTTotal - perScoreNumber + parseFloat(Math.abs(nowScoreNum))
              }
          })
        }
        this.scoreTotal.scoreFBTotal=FBTotal+newFBTotal;
        this.scoreTotal.scoreScTotal=scTotal+newScTotal;
        this.scoreTotal.scoreMcTotal=MCTotal+newMCTotal;
        this.scoreTotal.scoreTFTotal=TFTotal+newTFTotal;
        this.scoreTotal.scoreSAQTotal=SAQTotal+newSAQTotal;
        this.scoreTotal.scoreRCTotal=RCTotal+newRCTotal;
        this.scoreTotal.scoreCTTotal=CTTotal+newCTTotal;
        if(this.templateDetailId){
          this.exercisesTypeSize.exercisesFB = this.exercisesTypeSize.exercisesPerFB+appendExercisesFBSize;
          this.exercisesTypeSize.exercisesSC = this.exercisesTypeSize.exercisesPerSC+appendExercisesSCSize;
          this.exercisesTypeSize.exercisesMC = this.exercisesTypeSize.exercisesPerMC+appendExercisesMCSize;
          this.exercisesTypeSize.exercisesTF = this.exercisesTypeSize.exercisesPerTF+appendExercisesTFSize;
          this.exercisesTypeSize.exercisesSAQ = this.exercisesTypeSize.exercisesPerSAQ+appendExercisesSAQSize;
          this.exercisesTypeSize.exercisesRC = this.exercisesTypeSize.exercisesPerRC+appendExercisesRCSize;
          this.exercisesTypeSize.exercisesCT = this.exercisesTypeSize.exercisesPerCT+appendExercisesCTSize;
        }
        this.totalExercisesNumber = this.exercisesTypeSize.exercisesFB+this.exercisesTypeSize.exercisesSC+this.exercisesTypeSize.exercisesMC+this.exercisesTypeSize.exercisesTF+this.exercisesTypeSize.exercisesSAQ+this.exercisesTypeSize.exercisesRC+this.exercisesTypeSize.exercisesCT;
        // this.deleteChooseQuestionList = [];
        // this.updateChooseQuestionList = [];
        // examPapersItemIds 删除的数据id
        return this.scoreTotal.scoreFBTotal+this.scoreTotal.scoreScTotal+this.scoreTotal.scoreMcTotal+this.scoreTotal.scoreTFTotal+this.scoreTotal.scoreSAQTotal+this.scoreTotal.scoreRCTotal+this.scoreTotal.scoreCTTotal;
      }
    },
    updated() {
      MathJax.typesetPromise();
    }
  }
</script>
<style scoped>
@import "../../../public/css/addExaminationPaper.css";
</style>

