<template>
    <div class="classCenterBg">
        <div>
            <div class="classCenterContent">
              <userHead></userHead>
                <div class="classCenterBig">
                    <!-- 左侧边栏 -->
                    <div class="userCnerter" style="margin-top: 20px">
                        <el-row class="tac" style="width: 200px;box-shadow: 0px 0px 6px rgba(103, 103, 103, 0.31);border-radius: 4px;">
                        <el-col>
                            <el-menu
                                :default-active="defaultActive"
                                class="el-menu-vertical-demo"
                                :default-openeds="menuDefaultOpeneds"
                                @select="selectOpen"
                                ref="userCenterMenu"
                            >
                            <el-menu-item index="1">
<!--                                <img src="images/icon1.png" alt="" class="iconMargin"/>-->
                                <span slot="title">我的E小智</span>
                            </el-menu-item>
                            <el-submenu index="2">
                                <template slot="title">
<!--                                <img src="images/icon4.png" alt="" class="iconMargin"/>-->
                                <span>学习中心</span>
                                </template>
                                <el-menu-item-group>
                                <el-menu-item index="2-1">
                                  <div class="stdIconFlex">
                                    <img src="images/stdIcon1.png" />
                                    <span>我的课程</span>
                                  </div>
                                </el-menu-item>
                                <el-menu-item index="2-2">
                                  <div class="stdIconFlex">
                                    <img src="images/stdIcon2.png" />
                                    <span>我的收藏</span>
                                  </div>
                                </el-menu-item>
                                <el-menu-item index="2-3">
                                  <div class="stdIconFlex">
                                    <img src="images/stdIcon3.png" />
                                    <span>学习笔记</span>
                                  </div>
                                </el-menu-item>
                                <!-- <el-menu-item index="2-3">做题记录</el-menu-item> -->
                                </el-menu-item-group>
                            </el-submenu>
                            <el-submenu index="3">
                                <template slot="title">
<!--                                <img src="images/icon4.png" alt="" class="iconMargin"/>-->
                                <span>订单中心</span>
                                </template>
                                <el-menu-item-group>
                                <el-menu-item index="3-1">
                                  <div class="stdIconFlex">
                                    <img src="images/stdIcon5.png" />
                                    <span>我的订单</span>
                                  </div>
                                </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>
                            <el-submenu index="4">
                                <template slot="title">
<!--                                <img src="images/icon4.png" alt="" class="iconMargin"/>-->
                                <span>用户中心</span>
                                </template>
                                <el-menu-item-group>
                                <el-menu-item index="4-1">
                                  <div class="stdIconFlex">
                                    <img src="images/stdIcon6.png" />
                                    <span>我的班级</span>
                                  </div>
                                </el-menu-item>
                                <el-menu-item index="4-2">
                                  <div class="stdIconFlex">
                                    <img src="images/stdIcon7.png" />
                                    <span>个人中心</span>
                                  </div>
                                </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>
                            </el-menu>
                        </el-col>
                        </el-row>
                    </div>
                    <!-- 右侧内容 -->
                    <div style="width:100%;margin-left:20px">
                        <div>
                            <component :is="isComponent" @updateComponent="updateComponent" :loginUser="loginUserData" @updateUserInfo="updateUserInfo" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import userHead from './components/userHead'
import IEXiaoZhi from './components/myEXiaoZhi'
import ICourseList from './components/myCourseList'
import ICollectionCourseList from './components/myCollectionCourseList.vue'
import IClass from './components/myClass.vue'
import IStudyNotes from './components/myStudyNotes'
import ISelfCenter from './components/selfCenterIndex.vue'
import myOrder from './components/myOrder.vue'
import {loginByPassword,userCourseList} from '@/api/user'
import Cookie from 'js-cookie';
export default {
    name:"userCenterIndex",
    data(){
        return{
            isComponent: '',
            menuDefaultOpeneds:['1','2','3','4'],
            loginUserData:{},
            isOverdue:0,//是否过期，0否1是
            defaultActive:"1",
        }
    },
    created(){
        this.login();
        this.selectOpen("1");
        this.defaultActive='1';
        this.$other.$emit('setIndexNav',1);
        let stdSetNumber=sessionStorage.getItem('stdSetNumber');
        if(stdSetNumber){
          this.selectOpen('4-2');
          this.defaultActive='4-2';
          sessionStorage.removeItem("stdSetNumber")
        };
      let stdQuickEntryShow=sessionStorage.getItem('stdQuickEntryShow');
      if(stdQuickEntryShow){
        if(stdQuickEntryShow==3){
          this.selectOpen('2-1');
          this.defaultActive='2-1';
          sessionStorage.removeItem("stdQuickEntryShow")
        }else if(stdQuickEntryShow==4){
          this.selectOpen('2-2');
          this.defaultActive='2-2';
          sessionStorage.removeItem("stdQuickEntryShow")
        }else if(stdQuickEntryShow==5){
          this.selectOpen('2-3');
          this.defaultActive='2-3';
          sessionStorage.removeItem("stdQuickEntryShow")
        }else if(stdQuickEntryShow==6){
          this.selectOpen('3-1');
          this.defaultActive='3-1';
          sessionStorage.removeItem("stdQuickEntryShow")
        }else if(stdQuickEntryShow==7){
          this.selectOpen('4-1');
          this.defaultActive='4-1';
          sessionStorage.removeItem("stdQuickEntryShow")
        }else if(stdQuickEntryShow==9){
          this.selectOpen('4-2');
          this.defaultActive='4-2';
          sessionStorage.removeItem("stdQuickEntryShow")
        }
      }
    },
    components:{
        IEXiaoZhi,
        ICourseList,
        IClass,
        IStudyNotes,
        ISelfCenter,
        ICollectionCourseList,
        myOrder,
        userHead
    },
    computed: {

    },
    updated() {

    },
    methods:{
        login(){
          this.loginUserData = JSON.parse(localStorage.getItem("userInfo"))
          this.queryLoginUserCourseList();
        },
        selectOpen(index, indexPath) {
        let map = {
            '1':'IEXiaoZhi',  // 我的E小智
            '2-1':'ICourseList',  // 我的课程
            '2-2':'ICollectionCourseList',
            '2-3':'IStudyNotes', //学习笔记
            '3-1':'myOrder',  // 我的订单
            '4-1':'IClass',  // 我的班级
            '4-2':'ISelfCenter' //个人中心
        }
        this.isComponent = map[index];
        this.defaultActive = index;
        },
        //获取用户课程列表
        queryLoginUserCourseList(){
            userCourseList({isOverdue:this.isOverdue,page:1,size:2}).then(res=>{

            })
        },
        updateComponent(index){
            this.selectOpen(index,"");
            this.defaultActive = index+"";
        },
        updateUserInfo(userInfo){
            //更新用户信息缓存
            localStorage.setItem("userInfo",JSON.stringify(userInfo));
            this.loginUserData = userInfo;
        }
    }
}
</script>
<style scoped>
@import "../../../public/css/classCenter.css";
</style>
