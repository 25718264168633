<template>
  <div class="examinationAnalyseBg">
    <div class="examinationAnalyseContent">
      <div>
<!--        <span>试卷名称：</span>-->
        <span class="kaoshiTitles">{{examinationTitle}}</span>
<!--        <span>丨考试时间：30分钟</span>-->
<!--        <span>丨考试权限：所有考生</span>-->
      </div>
    </div>
    <div class="examinationAnalyseContent ">
      <div class="examinationAnalysePad">
        <div class="statisticsFlex">
          <p @click="changeAnalyse(1)" :class="analyseShow==1?'AnalyseActive':'AnalyseTab'">统计分析</p>
          <p @click="changeAnalyse(2)" :class="analyseShow==2?'AnalyseActive':'AnalyseTab'">成绩详情</p>
        </div>
<!--        统计分析内容-->
        <div v-if="analyseShow==1">
    <!--     试卷成绩总结 -->
          <div>
            <p class="kaoshizongjie">
              <span></span>
              <span>试卷成绩总结</span>
            </p>
            <div class="tableBorder">
              <el-table
                  :data="statisticsScore"
                  style="width: 100%">
                <el-table-column
                    prop="examinationScore"
                    label="试卷总分"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="avg_score"
                    label="平均分">
                </el-table-column>
                <el-table-column
                    prop="max_score"
                    label="最高分">
                </el-table-column>
                <el-table-column
                    prop="min_score"
                    label="最低分">
                </el-table-column>
                <el-table-column
                    prop="pass_people_number"
                    label="及格人数">
                </el-table-column>
                <el-table-column
                    prop="total_people_number"
                    label="总人数">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="及格率">
                  <template slot-scope="scope">
                     <p v-if="scope.row.avg_score">{{(scope.row.pass_people_number)/Number(scope.row.total_people_number)*100}}%</p>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
    <!--     考试及格率 -->
          <div class="kaoshiPass">
            <echarts id="ExamData" :data="ExamData" :echartsTitle="echartsTitle" :legendShow=false></echarts>
          </div>
    <!--      各题型正确率-->
          <div>
            <p class="kaoshizongjie">
              <span></span>
              <span>各题型正确率</span>
            </p>
            <div class="tableBorder">
              <el-table
                :data="statisticsExercises"
                style="width: 100%">
              <el-table-column
                  prop="examinationScore"
                  label="题型"
                  width="180">
                <template slot-scope="scope">
                  <p v-if="scope.row.exercises_type=='SC'">单选题</p>
                  <p v-if="scope.row.exercises_type=='MC'">多选题</p>
                  <p v-if="scope.row.exercises_type=='FB'">填空题</p>
                  <p v-if="scope.row.exercises_type=='TF'">判断题</p>
                  <p v-if="scope.row.exercises_type=='SAQ'">简答题</p>
                </template>
              </el-table-column>
              <el-table-column
                  prop="total_exercises_number"
                  label="题目数">
                <template slot-scope="scope">
                  <p>{{scope.row.total_exercises_number}}</p>
                </template>
              </el-table-column>
              <el-table-column
                  prop="correct_number"
                  label="正确次数">
              </el-table-column>
              <el-table-column
                  prop="error_number"
                  label="错误次数">
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="正确率">
                <template slot-scope="scope">
                  <p>{{scope.row.correct_number/scope.row.total_exercises_number*100}}%</p>
                </template>
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="错误率">
                <template slot-scope="scope">
                  <p>{{scope.row.error_number/scope.row.total_exercises_number*100}}%</p>
                </template>
              </el-table-column>
            </el-table>
            </div>
          </div>
        </div>
<!--        成绩详情内容-->
        <div v-if="analyseShow==2">
<!--          已考完-->
          <div>
            <p class="kaoshizongjie">
              <span></span>
              <span>已考完</span>
              <span>{{Examination1Total}}</span>
            </p>
            <div class="tableBorder">
              <el-table
                  :data="examinationPerformanceListArr1"
                  style="width: 100%"
                  ref="finishedTable"
                  >
                <el-table-column
                    label="序号"
                    width="180"
                    prop="index"
                    type="index"
                    :index="finishedTableIndex">
                </el-table-column>
                <el-table-column
                    prop=""
                    label="考试者">
                    <template slot-scope="scope">
                      <p>{{scope.row.userName?scope.row.userName:scope.row.userTelNumber}}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="排名"
                    width="180"
                    prop="index"
                    type="index"
                    :index="finishedTableIndex">
                </el-table-column>
                <el-table-column
                    prop="totalScore"
                    label="得分">
                </el-table-column>
                <el-table-column
                    prop="examPapersTotalScore"
                    label="总分">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="操作">
                  <template slot-scope="scope">
                    <p @click="selectStdExamnationDetail(scope.row)" class="stdExamnationDetail">查看详情</p>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="margin: 30px 0">
              <el-pagination
                  class="text_center"
                  background
                  @current-change="handleExamination1Change"
                  :current-page.sync="Examination1Page"
                  :page-size="Examination1Size"
                  layout="total,  prev, pager, next, jumper"
                  :total="Examination1Total"
              >
              </el-pagination>
            </div>
          </div>
<!--          未考完-->
          <div>
            <div class="kaoshizongjie">
              <span></span>
              <span>未考完</span>
              <p>{{Examination2Total}}</p>
            </div>
            <div class="tableBorder">
              <el-table
                  :data="examinationPerformanceListArr2"
                  style="width: 100%"
                  ref="unfinishedTable"
                  >
                <el-table-column
                    label="序号"
                    type="index"
                    width="180">
                  <template slot-scope="scope">
                    <p>{{unfinishedTableIndex(scope.$index)}}</p>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="考试者"
                    >
                  <template slot-scope="scope">
                    <p>{{scope.row.userName?scope.row.userName:scope.row.userTelNumber}}</p>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div style="margin: 30px 0">
            <el-pagination
                class="text_center"
                background
                @current-change="handleExamination2Change"
                :current-page.sync="Examination2Page"
                :page-size="Examination2Size"
                layout="total,  prev, pager, next, jumper"
                :total="Examination2Total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {examinationStatistics,examinationPerformanceList} from "@/api";
import echarts from "@/components/echarts";
export default {
  components:{
    echarts,
  },
  data(){
    return{
      tableData: [{
        date: '100',
        name: '50',
        address: '60'
      }, {
        date: '100',
        name: '50',
        address: '60'
      }, {
        date: '201',
        name: '50',
        address: '60'
      }, {
        date: '20',
        name: '50',
        address: '60'
      }],
      analyseShow:1,
      papersTestCenterId:'',
      examinationStatisticsObj:{},
      statisticsScore:[],
      statisticsExercises:[],
      examinationTitle:'',
      examinationPerformanceListArr1:[],
      examinationPerformanceListArr2:[],
      Examination1Page:1,
      Examination2Page:1,
      Examination1Size:10,
      Examination2Size:10,
      Examination1Total:0,
      Examination2Total:0,
      ExamData:[],
      echartsTitle:'考试及格率'
    }
  },
  methods:{
    changeAnalyse(index){
      this.analyseShow=index;
      if(index==2){
        this.getExaminationPerformanceList();
        this.getExaminationPerformanceList2()
      }
    },
    finishedTableIndex(index) {
      return (this.Examination1Page  - 1) * this.Examination1Size + index + 1;
      // return index + (this.Examination1Page * this.Examination1Size) -9 ;
    },
    unfinishedTableIndex(index) {
      return (this.Examination2Page  - 1) * this.Examination2Size + index + 1;
      // return index + (this.Examination2Page * this.Examination2Size) -9 ;
    },
  //  考场分析统计
    getExaminationStatistics(){
      let data={
        papersTestCenterId:this.papersTestCenterId
      }
      examinationStatistics(data).then(res=>{
        if(res.data){
          let pass=res.data.statisticsScore.pass_people_number/res.data.statisticsScore.total_people_number
          let obj1={
            value:pass,
            name:'及格'
          }
          this.ExamData.push(obj1);
          let noPass=1-pass
          let obj2={
            value:noPass,
            name:'不及格'
          }

          this.ExamData.push(obj2)
          this.examinationStatisticsObj=res.data;
          let statisticsScore=res.data.statisticsScore
          statisticsScore.examinationScore=this.$route.query.examinationScore
          this.statisticsScore.push(statisticsScore);
          this.statisticsExercises=res.data.statisticsExercises
        }

      })
    },
  //  成绩列表
    getExaminationPerformanceList(){
      let data={
        examPapersTestCenterId:this.papersTestCenterId,
        page:this.Examination1Page,
        size:this.Examination1Size,
        progressType:'AR,NR',

      };
      examinationPerformanceList(data).then(res=>{
        this.examinationPerformanceListArr1=res.data.records;
        this.Examination1Total=parseInt(res.data.total)
      })
    },
    getExaminationPerformanceList2(){
      let data={
        examPapersTestCenterId:this.papersTestCenterId,
        page:this.Examination2Page,
        size:this.Examination2Size,
        progressType:'TBC'
      };
      examinationPerformanceList(data).then(res=>{
        this.examinationPerformanceListArr2=res.data.records;
        this.Examination2Total=parseInt(res.data.total);
      })
    },
  //  考完的分页
    handleExamination1Change(Examination1Page){
      this.Examination1Page=Examination1Page;
      this.getExaminationPerformanceList()
    },
    //未考完分页
    handleExamination2Change(Examination2Page){
      this.Examination2Page=Examination2Page;
      this.getExaminationPerformanceList2()
    },
  //  查看学生试卷详情\
    selectStdExamnationDetail(item){
      let examPapersStudentInfo={
        userName:item.userName,
        userTelNumber:item.userTelNumber,
        answerEndTime:item.answerEndTime,
        examPapersTestCenterName:item.examPapersTestCenterName,
        studentNumber:item.userStudentNumber,
        name:item.userName
      };
      item.examPapersStudentInfo=examPapersStudentInfo
      sessionStorage.setItem('studentExamDetail',JSON.stringify(item) )
      this.$router.push({path:'modifyPaper',query:{courseexamId:item.examPapersTestCenterId,queryExamStudentId:item.userId,totalScore:item.totalScore,progressType:item.progressType}})
    }
  },
  mounted() {
    this.papersTestCenterId=this.$route.query.papersTestCenterId;
    this.getExaminationStatistics()
    this.examinationTitle=this.$route.query.name;
    this.$other.$emit('setIndexNav',1);
  }
}
</script>
<style scoped>
@import "../../../public/css/examinationAnalyse.css";
</style>
