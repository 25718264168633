<template>
  <div>
    <div v-for="(item,index) in courseListData" :key="index">
      <el-row>
        <el-col :span="24">
          <el-row  style="margin-top:10px">
            <el-col :span="5">
              <img
                  style="width: 200px;border-radius:5px;"
                  :src="item.course.logo" class="stdCourseImgCenter" />
            </el-col>
            <el-col :span="6" :offset="1">
              <div style="height:130px;display: table;">
                <div style="display: table-cell;vertical-align:middle;">
                  <p style="font-weight:bold">{{item.course.name}}</p>
                  <p v-if="item.course.teacherUser" style="margin-top:20px">主讲教师：{{item.course.teacherUser.name}}</p>
                  <p class="profils">{{item.sectionNameOrVideo}}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6" :offset="5">
              <div style="height:130px;display: table;">
                <div style="display: table-cell;vertical-align:middle;text-align:center;">
                  <p style="font-weight:bold" ><el-button type="primary"  @click="goToCourseDetail(item)">课程详情</el-button></p>
                  <!-- <p style="margin-top:20px"><el-button class="ordBtn">继续学习</el-button></p> -->
<!--                  <p v-if="isCollectionList" style="font-weight:bold;margin-top:10px" ><el-button type="primary" @click="deletedCollectionCourse(item)">取消收藏</el-button></p>-->
                </div>
              </div>
            </el-col>

          </el-row>
        </el-col>
      </el-row>
      <el-divider></el-divider>
    </div>
    <div v-if="!courseListData.length" style="width: 100%">
      <el-empty :image-size="200"></el-empty>
    </div>
  </div>
</template>
<script>
import {cancleCollectCource,deletedUserCollectionCourseId} from "@/api/index.js"
export default {
  name:"courseList",
  props: {
    courseListData:{
      type:Array,
      default(){
        return [];
      }
    },
    isCollectionList:{
      type:Boolean,
      default(){
        return false
      }
    },
  },
  data(){
    return{

    }
  },
  created(){

  },
  components:{

  },
  computed: {

  },
  updated() {

  },
  methods:{
    goToCourseDetail(item){
      if(item.course.forEnterprise=='0'){
        sessionStorage.setItem('courseDetail',JSON.stringify(item.course));
        let routeUrl = this.$router.resolve({
          path: "/courseDetail",
        });
        window.open(routeUrl.href);
      }else{
        sessionStorage.setItem('courseVideoDetails',JSON.stringify(item.course) )
        let routeUrl = this.$router.resolve({
          path: "/courseVideoDetais",
        });
        window.open(routeUrl.href);
      }
    },
    deletedCollectionCourse(courseData){
      let courseId = courseData.id;
      cancleCollectCource({courseId:courseId}).then(res=>{
        if(res.code=="0"&&res.data==true){
          this.$emit("refreshDataFunc",1);
        }
      })
    }
  }
}
</script>
<style scoped>
.goOnstudyBtn{
  background-image: linear-gradient(to right, #49B151 , #73D27B);
  color: white;
  cursor: pointer;
  border-radius: 8px;
}
.ordBtn{
  color: #49B151;
  cursor: pointer;
  border-radius: 8px;
}
.stdCourseImgCenter{
  object-fit: cover;
}
.profils{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 382px;
  margin-top: 12px;
  font-size: 12px;
  color: #666666;
}
</style>
