<template>
  <div class="postCourseBg" >
    <div class="homeBanner" @click="hideMaskPopup">
      <el-carousel :interval="600000000" arrow="always">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <img :src="item.filePath" alt="" style="width: 100%;height: 100%"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div style="width: 100%" @click="hideMaskPopup">
      <div class="kernelCourseCenter" >
        <p>职位列表</p>
        <div v-for="(item,index) in kernelCourseArr" :key="index" >
  <!--        <div class="kernelCourse" v-for="(item,index) in kernelCourseArr" :key="index" >-->
  <!--          <img :src="item.iconUrl?item.iconUrl:'images/zanwuImgs.png'">-->
  <!--          <p>{{item.name}}</p>-->
  <!--        </div>-->
          <p>{{item.name}}</p>
          <template>
            <div  class="workplaceClassfyFlex">
              <div v-for="(item1,index1) in item.children" :key="index1">
                <p  class="kernelCourseCenterClassfy" @click="goPostCourseDetail(item1,item)">
                  <template v-if="item1.iconUrl">
                    <img class="kernelCourseCenterClassfyimg" :src="item1.iconUrl?item1.iconUrl:''">
                  </template>
                  <span>{{item1.name}}</span>
                </p>
              </div>
            </div>
  <!--          <div class="preference" v-html="item.overview"></div>-->
          </template>
        </div>
        <div style="justify-content: center;margin: 40px 0 60px 0">
          <img src="images/postIMg.png">
        </div>
      </div>
      <div >
        <Footer></Footer>
      </div>

    </div>
    <div class="postCourseIocn">
      <div @click="buyCourseVip" style="cursor: pointer">
        <img src="images/marketIcon.png">
        <p style="color: #FFB512">VIP</p>
      </div>
      <div style="cursor: pointer" v-for="(item,index) in courseIconArr" :key="index" @click="changeCourseIcon(index)">
        <img :src="item.img">
        <p style="font-size: 12px">{{item.name}}</p>
      </div>
    </div>
    <div class="postPOsition">
      <div class="postCourseAbsolue" v-if="postCourseShow">
        <div>
          <span>合作交流</span>
          <img src="images/deletePOst.png" style="cursor: pointer;width: 16px" @click="deletePostWindow()">
        </div>
        <div class="postForm">
          <el-form ref="marketForm" :rules="rules" :model="marketForm"  label-width="80px">
            <template>
              <p style="font-size: 14px">山东智捷电子商务有限公司</p>
            </template>
            <template>
              <p style="font-size: 10px;color: #97989A;margin: 6px 0 12px 0">山东省济南市历城区荣盛时代国际1-725</p>
            </template>
            <el-form-item label="" prop="name" >
              <el-input v-model="marketForm.name" placeholder="请填写姓名"></el-input>
            </el-form-item>
            <el-form-item label="" prop="phone">
              <el-input v-model="marketForm.phone" placeholder="请填写手机号"></el-input>
            </el-form-item>
            <el-form-item label="" prop="copy">
              <el-input v-model="marketForm.copy" placeholder="请填写公司名称"></el-input>
            </el-form-item>
            <el-form-item label="" prop="remark">
              <el-input v-model="marketForm.remark" type="textarea"
                        :rows="2" placeholder="请填写备注"></el-input>
            </el-form-item>
          </el-form>
          <div style="display: flex;justify-content: flex-end">
<!--            <el-button type="primary" plain size="small" @click="deletePostWindow">取消</el-button>-->
            <el-button type="primary" size="small" @click="submitCourse('marketForm')" >提交</el-button>
          </div>
        </div>
      </div>
      <div class="postCourseAbsolueimg" v-if="copyAccounts">
        <img src="images/exiaozhiCode.png" >
      </div>
      <div class="postCoursestudyP" v-if="studyShow" >
        <div class="postStudy">
          悄悄学习  惊艳众人
        </div>
        <div class="postStudyBg">
          <div style="display: flex;align-items: center">
            <span>分类VIP</span>
            <img src="images/VIP.png">
          </div>
          <div class="studyBorder">
            <p v-for="(item,index) in kernelCourseArr" :key="index" @click="goOrder(item,index)">
              <img :src="item.iconUrl">
              <span>{{item.name}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import {bannerList, courseClassify,addLeaveWords} from '@/api/index'
import Footer from '../footer.vue'
  export default {
    components:{
      Footer,
    },
    data(){
      return{
        bannerList:[],
        bannerData:{
          fileCode: 'WEBZCTS'
        },
        kernelCourseArr:[],
        courseIconArr:[
          {id:1,img:'images/marketIcon1.png',name:'微信'},
          {id:2,img:'images/marketIcon2.png',name:'客服'},
          {id:3,img:'images/marketIcon3.png'},
        ],
        marketForm: {
          name: '',
          phone:'',
          copy:'',
          remark:'',
        },
        postCourseShow:false,
        copyAccounts:false,
        rules: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入电话', trigger: 'blur' }
          ],
        },
        studyShow:false
      }
    },
    methods:{
      //点击空白关闭弹窗
      hideMaskPopup(e){
        console.log(e.target)
        let mask = document.querySelector(".postPOsition");
        if (mask) {
          if (!mask.contains(e.target)) {//判断所点击的区域是否在.mask_popup中
            this.studyShow = false;
            this.postCourseShow=false;
            this.copyAccounts=false
          }
        }
      },
      //去订单确认页面
      goOrder(item,index){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if(userInfo&&userInfo.teacherType){
          this.$alert('sorry ,您无购买权限，请联系管理员或以个人账号登录购买', '购买', {
            confirmButtonText: '确定',
            callback: action => {
              this.$message({
                type: 'info',
                message: ''
              });
            }
          });
        }else{
          let routeUrl = this.$router.resolve({
            path: "/courseOrder",
            query:{type:'allTotal',allPrice:'allPrice',classpId:item.id,profiles:item.profiles,classPName:item.name,classProfiles:item.profiles,orderShow:index==0?'0':'1'}
          });
          window.open(routeUrl.href);
          this.studyShow=false
        }

      },
      //提交留言板
      submitCourse(formName){
        let data={
          name:this.marketForm.name,
          phone:this.marketForm.phone,
          companyName:this.marketForm.copy,
          leaveWord:this.marketForm.remark,
          type:'ZCTS'
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$alert('感谢您的留言,我们会尽快联系您', '留言', {
              confirmButtonText: '确定',
              callback: action => {
                this.$message({
                  type: 'info',
                  message:'留言成功'
                });
              }
            });
            this.$message.success('留言成功');
            let that=this
            addLeaveWords(data).then(res=>{
              if(res.code==0){
                // that.$message({
                //   message: "留言成功",
                //   type: "success",
                //   duration: 2000
                // });
                this.postCourseShow=false
              }
            })
          } else {
            return false;
          }
        });

      },
      //返回顶部
      gotop(){
        document.documentElement.scrollTop=0
      },
      //关闭悬浮框
      deletePostWindow(){
        this.postCourseShow=false
      },
      //  获取banner
      getBanner() {
        bannerList(this.bannerData).then(res => {
          this.bannerList = res.data.records
        })
      },
      //购买vip
      buyCourseVip(){
        this.postCourseShow=false
        this.copyAccounts=false
        this.studyShow=!this.studyShow
        this.courseIconArr[2].img='images/marketIcon3.png'
        this.courseIconArr[0].img='images/marketIcon1.png'
        this.courseIconArr[1].img='images/marketIcon2.png'
      },
      //改变悬浮框
      changeCourseIcon(index){
        if(index==1){
          this.postCourseShow=!this.postCourseShow
          this.copyAccounts=false
          this.studyShow=false
          this.courseIconArr[1].img='images/marketIcon5.png'
          this.courseIconArr[0].img='images/marketIcon1.png'
          this.courseIconArr[2].img='images/marketIcon3.png'
        }
        if(index==0){
          this.copyAccounts=!this.copyAccounts
          this.postCourseShow=false
          this.studyShow=false
          this.courseIconArr[0].img='images/marketIcon4.png'
          this.courseIconArr[1].img='images/marketIcon2.png'
          this.courseIconArr[2].img='images/marketIcon3.png'
        }
        if(index==2){
          this.gotop()
          this.postCourseShow=false
          this.copyAccounts=false
          this.studyShow=false
          this.courseIconArr[2].img='images/marketIcon6.png'
          this.courseIconArr[0].img='images/marketIcon1.png'
          this.courseIconArr[1].img='images/marketIcon2.png'
        }
      },
    //  去岗位课程详情
      goPostCourseDetail(item,bigItem){
        let classfiy=[]
        this.kernelCourseArr.forEach((item1,index1)=>{
          if(item1.id==bigItem.id){
            item1.children.forEach(item2=>{
              if(item.id!==item2.id){
                classfiy.push(item2)
              }
            })
          }
        })
        sessionStorage.setItem('classfiy',JSON.stringify(classfiy))
        let newpage = this.$router.resolve({
          name: 'postCourseDetail',
          query:{
            classFyId:item.id,
            classPid:bigItem.id,
            classPName:bigItem.name,
            classProfiles:bigItem.profiles

          }
        })
        window.open(newpage.href);
      },
      //课程分类列表
      getCourseClassify(){
        courseClassify().then(res => {
          this.kernelCourseArr=res.data
        })
      },
      //选同类给下页面
      changePOst(item){

      }
    },
    mounted() {
      this.getBanner();
      this.getCourseClassify();
      this.$other.$emit('setIndexNav',4);
    },
  }
</script>
<style scoped>
@import "../../../public/css/postCourse.css";
</style>
