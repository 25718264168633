<template>
  <div class="aboutBg">
    <div>
<!--      <div>-->
<!--        <img src="images/enterpriseBanner.png" style="width: 100%"/>-->
<!--      </div>-->
      <div >
        <div class="aboutWidth">
          <div>
            <div class="leftIconImg"  v-for="(item,index) in aboutIcon" :key="index" :class="aboutIconShow==index?'aboutIconActive':''" @click="changeIcon(index)">
              <img :src="item.img"/>
              <p>{{item.name}}</p>
            </div>
          </div>
          <div style="width: 100%;min-height: 600px">
            <el-card class="enterpriseSynopsisContent" v-if="aboutIconShow==0">
              <div class="enterpriseSynopsisFlex">
                <span></span>
                <span>企业简介</span>
              </div>
              <div class="enterpriseSynopsistext">
                <p>
                  山东智捷电子商务有限公司（简称：智捷电商）顺应国家电商产业发展大趋势，紧跟职业教育之新风，校企合作、产教融合，面向中高职院校率先推出多维度、多层次、软硬一体化基于真实应用驱动教学的电子商务高水平专业群建设整体解决方案。
                </p>
                <p>
                  公司秉承“育工匠、融校企、促就业、创未来”的核心价值观，致力于构建电子商务“教、学、训、考、赛、就、创、评”八大模块数字化人才服务生态体系，全方位助力学生终身成长！
                </p>
                <p>
                  公司目前与全国多省份职业院校有紧密合作，下设“教研中心、研发中心、 营销中心、运营中心、产业中心、人力行政中心和财务中心”七大核心职能部门，拥有实战经验丰富的高素质、高水平的“双师型”教研团队、软件技术开发和在线课程研发团队、电商产业运营管理团队、就创业指导服务顾问团队。
                </p>
              </div>
              <div class="exhibitionImgFlex">
                <p>企业展示</p>
                <el-image src="images/aboutBg.png"></el-image>
              </div>
            </el-card>
            <el-card class="enterpriseSynopsisContent" v-if="aboutIconShow==1">
              <div class="enterpriseSynopsisFlex">
                <span></span>
                <span>企业文化</span>
              </div>
              <img class="aboutBg2Wid" src="images/aboutBg2.png">
            </el-card>
            <el-card class="enterpriseSynopsisContent" v-if="aboutIconShow==2">
              <div class="enterpriseSynopsisFlex">
                <span></span>
                <span>发展历程</span>
              </div>
              <img class="aboutBg2Wid" src="images/aboutBg8.svg">
            </el-card>
            <el-card class="enterpriseSynopsisContent" v-if="aboutIconShow==3">
              <div class="enterpriseSynopsisFlex">
                <span></span>
                <span>荣誉资质</span>
              </div>
              <div style="margin-bottom: 32px">
                2018年11月，被认定为国家高新技术企业;2019年5月，被认定双软企业;2019年8月，顺利通过ISO9001质量管理体系认证;2020年3月，被评为AAA级信用企业;2020年3月，荣获山东省科学技术厅入库科技型中小企业;2020年，被国家税务总局评为纳税人信用等级A级;2020年12月，公司独立研发的“中高职电子商务真实运营实训教学平台”顺利通过济南市科技局金种子企业关键产品提升计划验收。
              </div>
              <img class="aboutBg2Wid" src="images/aboutBg3.png">
            </el-card>
            <el-card class="enterpriseSynopsisContent" v-if="aboutIconShow==4">
              <div class="enterpriseSynopsisFlex">
                <span></span>
                <span>合作案例</span>
              </div>
              <img class="aboutBg2Wid" src="images/aboutBg4.svg">
            </el-card>
            <el-card class="enterpriseSynopsisContent" v-if="aboutIconShow==5">
              <div>
                <div class="enterpriseSynopsisFlex">
                  <span></span>
                  <span>人才招聘</span>
                </div>
                <p>历经多年的行业深耕及技术积累，智捷电商在业内具有高度的口碑，我们将持续在教育领域上创新及钻研，为我们客户提供更专业化的产品与服务。同时也热切盼望对未来充满抱负、想大展身手的您能加入我们团队！</p>
              </div>
              <!--                薪酬与福利-->
              <div>
                <h4 class="aboutBgFontTitle">薪酬与福利</h4>
                <img class="aboutBg2Wid" src="images/aboutBg7.svg">
              </div>
              <!--               招聘职位-->
              <div>
                <h4 class="aboutBgFontTitle">招聘职位</h4>
                <img class="aboutBg2Wid" src="images/aboutBg4.png">
              </div>
            </el-card>
            <el-card class="enterpriseSynopsisContent" v-if="aboutIconShow==6">
              <div class="enterpriseSynopsisFlex">
                <span></span>
                <span>联系我们</span>
              </div>
              <div class="businessCollaborate">
                <h4>商务合作</h4>
                <div>
                  <p>立即加入我司渠道合作伙伴体系，为客户提供更专业化的产品与服务，携手共赢！</p>
                  <p>联系电话：0531-88259780</p>
                </div>
              </div>
              <img class="aboutBg2Wid" src="images/aboutBg5.png">
            </el-card>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        tabPosition: 'left',
        aboutIcon:[
          {id:1,name:'企业简介',img:'images/about1.png'},
          {id:1,name:'企业文化',img:'images/about2.png'},
          {id:1,name:'发展历程',img:'images/about3.png'},
          {id:1,name:'荣誉资质',img:'images/about4.png'},
          {id:1,name:'合作案例',img:'images/about5.png'},
          {id:1,name:'人才招聘',img:'images/about6.png'},
          {id:1,name:'联系我们',img:'images/about7.png'},
        ],
        aboutIconShow:0

      }
    },
    methods:{
      changeIcon(index){
        this.aboutIconShow=index
      }
    },
    mounted() {
      this.$other.$emit('setIndexNav',5);
    }
  }
</script>
<style scoped>
@import "../../../public/css/about.css";
</style>
